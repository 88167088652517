import type { PaginatedPsProjects, PsHeader, PsProjectDetails } from './types'

export type ParameterSpaceActions =
  | PsFetchProjectsByDate
  | PsFetchProjectById
  | PsSetAxisData
  | PsFetchProjectsByUserId

export const FETCH_PROJECTS_BY_DATE = 'FETCH_PROJECTS_BY_DATE'
export const FETCH_PROJECT_BY_ID = 'FETCH_PROJECT_BY_ID'
export const SET_AXIS_DATA = 'SET_AXIS_DATA'
export const FETCH_PROJECTS_BY_USER_ID = 'FETCH_PROJECTS_BY_USER_ID'

// Get project data
export interface PsFetchProjectsByDate {
  type: 'FETCH_PROJECTS_BY_DATE'
  data: PaginatedPsProjects
}

export interface PsFetchProjectById {
  type: 'FETCH_PROJECT_BY_ID'
  data: PsProjectDetails | null
}

export interface PsSetAxisData {
  type: 'SET_AXIS_DATA'
  headers: PsHeader[]
}

export type PsFetchProjectsByUserId = {
  type: 'FETCH_PROJECTS_BY_USER_ID'
  data: PaginatedPsProjects
}
