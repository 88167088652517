import { MsalProvider } from '@azure/msal-react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react'

import '@arc-web/components/dist/themes/index.css'
import '@arc-web/components/dist/themes/light.css'
import '@arc-web/components/dist/themes/dark.css'
import { setBasePath } from '@arc-web/components/dist/utilities/base-path'
setBasePath(window.location.origin)

import { store } from '~store/store'
import SignIn from './pages/signin/SignIn'
import { msal } from './lib/auth/auth'

import { InformThemeProvider } from './styles/ArcTheme'
import './styles/style.css'
import 'typeface-roboto'
import { Suspense } from 'react'

import { Routes, Route } from 'react-router-dom'

import Projects from 'src/pages/projects/Projects'
import AddProject from 'src/pages/projects/AddProject'
import AppBar from '~pages/AppBar'
import Dashboard from '~pages/dashboard/Dashboard'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

const App = () => {
  return (
    <>
      <AppBar />
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Projects />} />
          <Route path="/upload" element={<AddProject />} />
          <Route path="/project/:projectId" element={<Dashboard />} />
          <Route path="*" element={<SignIn />} />
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Suspense fallback={null}>
          <SignIn />
        </Suspense>
      </UnauthenticatedTemplate>
    </>
  )
}

export default App

ReactDOM.render(
  <InformThemeProvider>
    <MsalProvider instance={msal}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>

          <ReactQueryDevtools initialIsOpen={false} />
        </Provider>
      </QueryClientProvider>
    </MsalProvider>
  </InformThemeProvider>,
  document.getElementById('root')
)
