import '@arc-web/components/dist/components/icon-button/arc-icon-button'
import { Person, ViewType } from '@microsoft/mgt-react'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'

interface IProps {
  showInputSideBar: boolean
  setShowInputSideBar: (show: boolean) => void
  title: string
  creatorId: string
  userRole: string
}

const TitleBar: React.FC<any> = ({
  showInputSideBar,
  setShowInputSideBar,
  title,
  creatorId
}: IProps) => {
  return (
    <TitleBarContainer className={showInputSideBar ? '' : 'collapsed'}>
      <Typography variant="h4" fontWeight={600} color={'text.primary'}>
        {title}
      </Typography>
      <Person
        userId={creatorId}
        view={ViewType.twolines}
        // line2Property="jobTitle"
        avatarSize="small"
      ></Person>
    </TitleBarContainer>
  )
}

export default TitleBar

const TitleBarContainer = styled('div')({
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  right: 'auto',
  zIndex: 1,
  gap: '0.5rem',
  display: 'flex',
  flexDirection: 'column'
})
