import React, { memo, useEffect, useState } from 'react'

import type { ChartData } from 'chart.js'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import type { BarChartProps } from './BarChart.types'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  indexAxis: 'x' as const,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false as const
    }
  },
  scales: {
    y: {
      ticks: {
        font: {
          size: 11
        }
      }
    },
    x: {
      ticks: {
        maxRotation: 90,
        minRotation: 40,
        font: {
          size: 11,
          family: 'var(--arc-font-body)'
        }
      }
    }
  }
}

export interface IProps {
  data: BarChartProps
}

const BarChart: React.FC<IProps> = (props: IProps) => {
  const { headers, data, colors } = props.data
  const [chartData, setChartData] = useState<ChartData<'bar'>>()

  useEffect(() => {
    if (data) {
      const _colors = colors
      const _chartData = {
        labels: headers,
        datasets: [
          {
            data: data,
            backgroundColor: _colors.map(o => o.alpha(0.8).string()),
            borderColor: 'white',
            borderWidth: 1,
            barThickness: 15,
            borderRadius: 3
          }
        ]
      }
      setChartData(_chartData)
    }
  }, [headers, data, colors])

  return (
    <>
      {chartData ? (
        <div style={{ height: '220px', paddingBottom: '10px' }}>
          <Bar data={chartData} options={options} />{' '}
        </div>
      ) : null}
    </>
  )
}
export default memo(BarChart)
