import type { IInput, IItem } from '../../../declarations'

export const initializeInputData = (inputComponents?: IInput[]) => {
  const initialModuleInputDataDictionary = {}

  if (!inputComponents) {
    return
  }

  const groups = [] as any
  inputComponents.forEach(element => {
    groups.push(element.groups[0].items)
  })

  const flattened = groups.flat()
  flattened.forEach(component => {
    const inputName = component.param.split(':')[1]

    if (component.type === 'Number Slider') {
      initialModuleInputDataDictionary[component.param] = [
        component.default[0]._v.value ||
          component.validation[0]._v.min ||
          component.validation[0]._v.max
      ]
    } else if (
      component.type === 'Value List' ||
      component.type === 'Check List'
    ) {
      // Store [name, value] of value list options, based on the data type
      if (!initialModuleInputDataDictionary[component.param]) {
        initialModuleInputDataDictionary[component.param] = [] as any
      }
      if (!component.options[0].listData) {
        return
      }
      component.options[0].listData.forEach(option => {
        if (!option.Selected) {
          return
        }
        if (!isNaN(Number(option.value))) {
          initialModuleInputDataDictionary[component.param].push(
            Number(option.Value)
          )
        } else {
          initialModuleInputDataDictionary[component.param].push(
            option.Value.split(',')[0]
          )
        }
      })
    } else if (component.type === 'Boolean Toggle') {
      initialModuleInputDataDictionary[component.param] = [
        component.options[0]._v.value || false
      ]
    } else if (component.type === 'Panel' || component.type === 'Upload CSV') {
      const option = component.options[0]
      initialModuleInputDataDictionary[component.param] =
        option.value !== null
          ? [component.options[0].Value] ||
            component.options[0].Value.split('\r\n')
          : option.panelValue.split('\r\n') || ''
    }
  })

  return initialModuleInputDataDictionary
}

//To keep track of input names valuelist and checklist
export const initializeInputNames = inputComponents => {
  const initInputNamesDictionary = {}

  if (!inputComponents) {
    return
  }

  const groups = [] as any
  inputComponents.forEach(element => {
    groups.push(element.groups[0].items)
  })

  const flattened = groups.flat()
  flattened.forEach((component: IItem) => {
    if (component.type === 'Value List' || component.type === 'Check List') {
      // Store [name, value] of value list options, based on the data type
      if (!initInputNamesDictionary[component.param]) {
        initInputNamesDictionary[component.param] = [] as any
      }
      component.options[0].listData.forEach(option => {
        if (!option.Selected) {
          return
        }
        initInputNamesDictionary[component.param].push(option.Name)
      })
    }
  })
  return initInputNamesDictionary
}

export const marksGenerator = (component: any) => {
  const marks: {
    value: number
    label: number | string
  }[] = []
  const minValue = component.validation[0]._v.min
  const maxValue = component.validation[0]._v.max
  const intervalValue = component.validation[0]._v.interval

  let mark = minValue
  marks.push({
    value: minValue,
    label: minValue
  })

  if (intervalValue === 0) {
    while (mark + 0.1 < maxValue) {
      mark += 0.1
      marks.push({
        value: mark,
        label: ''
      })
    }
    marks.push({
      value: Math.min(mark + 0.1, maxValue),
      label: Math.min(mark + 0.1, maxValue)
    })
  } else {
    while (mark + intervalValue < maxValue) {
      mark += intervalValue
      marks.push({
        value: mark,
        label: ''
      })
    }
    marks.push({
      value: Math.min(mark + intervalValue, maxValue),
      label: Math.min(mark + intervalValue, maxValue)
    })
  }

  if (marks.length > 20) {
    return [
      {
        value: minValue,
        label: minValue
      },
      {
        value: maxValue,
        label: maxValue
      }
    ]
  }
  return marks
}
