import { Person, ViewType } from '@microsoft/mgt-react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { onClickUrl } from '~utils/onClickRedirect'

interface IProps {
  projectCount: number
  myProjects: any
  featuredProjects: any
  allProjects: any
}

export const ProjectSidebar: React.FC<IProps> = (props: IProps) => {
  return (
    <div
      className="projectSidebar"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '5rem'
      }}
    >
      <div className="topMenu">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Person
            personQuery="me"
            avatarSize="large"
            view={ViewType.threelines}
          ></Person>
        </div>

        {/* <ToolTip title={'header:userGuide'}>
              <arc-icon-button name="info" label="userGuide" href="https://staticapps.fuse.arup.com/appssg/07868995_PDSP/about/index.html" target="_blank" />
            </ToolTip> */}
        <arc-menu id="projectsMenu">
          <arc-menu-item
            value="home"
            onClick={() =>
              props.myProjects.current.scrollIntoView({
                behavior: 'smooth'
              })
            }
          >
            <arc-icon name="home" slot="prefix"></arc-icon>
            My Projects
          </arc-menu-item>
          <arc-menu-item
            value="eyeglass"
            onClick={() =>
              props.featuredProjects.current.scrollIntoView({
                behavior: 'smooth'
              })
            }
          >
            <arc-icon name="eyeglass" slot="prefix"></arc-icon>
            Featured Projects
          </arc-menu-item>
          <arc-menu-item
            value="grid"
            onClick={() =>
              props.allProjects.current.scrollIntoView({
                behavior: 'smooth'
              })
            }
          >
            <arc-icon name="grid" slot="prefix"></arc-icon>
            All Projects
          </arc-menu-item>
        </arc-menu>
      </div>
      <div className="bottomMenu">
        <div
          style={{
            marginLeft: '10%',
            width: '80%',
            height: '100%',
            backgroundColor: '#EFEFEF',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '10px',
            marginBottom: '2rem'
          }}
        >
          <img
            src="./assets/LearnInform.svg"
            alt="Learn Inform"
            style={{ width: '80%' }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '90%',
              paddingLeft: '5%',
              paddingRight: '5%',
              cursor: 'pointer'
            }}
          >
            <Typography
              onClick={onClickUrl(
                'https://web.yammer.com/main/org/arup.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMDM2OTE4MjEwNTYifQ/all'
              )}
              variant="caption"
              style={{ cursor: 'pointer' }}
            >
              <SvgContainer>
                <OpenInNewIcon />
              </SvgContainer>
              About
            </Typography>
            <Typography
              onClick={onClickUrl(
                'https://teams.microsoft.com/l/channel/19%3aaeec38a4b92643d0b7de57a21ae3e2eb%40thread.tacv2/Parameterspace%25202.0?groupId=14236b40-c95e-4e89-bc16-8e11b05a4595&tenantId=4ae48b41-0137-4599-8661-fc641fe77bea'
              )}
              variant="caption"
              style={{ cursor: 'pointer' }}
            >
              <SvgContainer>
                <OpenInNewIcon />
              </SvgContainer>
              Team
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              maxWidth: '90%',
              paddingLeft: '5%',
              paddingRight: '5%',
              cursor: 'pointer'
            }}
          >
            <Typography
              onClick={onClickUrl('https://feedback.arup.com/arup-inform/55')}
              variant="caption"
            >
              <SvgContainer>
                <OpenInNewIcon />
              </SvgContainer>
              Leave Feedback
            </Typography>
          </div>

          {/* <Typography variant="caption">Legal</Typography> */}
        </div>
      </div>
    </div>
  )
}

const SvgContainer = styled('span')({
  '& svg': {
    height: '0.75rem'
  }
})
