import { styled, Typography } from '@mui/material'
import React from 'react'

interface Props {
  data: any
}

const Legend: React.FC<Props> = (props: Props) => {
  const { title, values, colors } = props.data

  return (
    <UnitySettingsContainer className={''}>
      <Typography
        variant="caption"
        style={{
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          paddingRight: '0.25rem',
          maxWidth: '100px'
        }}
      >
        {title}
      </Typography>

      {values.map((marker, index) => (
        <p
          style={{
            margin: '0',
            display: 'flex',
            flexDirection: 'row',
            gap: '0.3rem'
          }}
          key={values[index]}
        >
          <div
            style={{
              width: '30px',
              minWidth: '30px',
              minHeight: '20px',
              backgroundColor: `rgb(${colors[index]})`
            }}
          ></div>
          <Typography
            variant={'caption'}
            style={{
              alignSelf: 'flex-end'
            }}
          >
            {values[index]}
          </Typography>
        </p>
      ))}
    </UnitySettingsContainer>
  )
}
export default Legend

const UnitySettingsContainer = styled('div')({
  position: 'absolute',
  bottom: '1rem',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0.5rem',
  right: '320px',

  '&.collapsed': {
    right: '1%'
  }
})
