import React from 'react'
// import React, { useRef } from "react";
import '@arc-web/components/dist/components/button/arc-button'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  styled,
  useTheme
} from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { InputItem } from './styles/styles'
import type { IInputItem } from './types/types'

const ValueList: React.FC<IInputItem> = (props: IInputItem) => {
  const component = props.component
  const theme = useTheme()
  return (
    <InputItem>
      <FormControl fullWidth>
        <Tooltip title={component.description} placement="bottom-start" arrow>
          <InputLabel id={component.param}>{component.name}</InputLabel>
        </Tooltip>
        <Select
          labelId={component.description}
          id={component.param}
          value={props.value as any}
          label={component.name}
          onChange={e =>
            props.onChange([
              Number(e.target.value) ? Number(e.target.value) : e.target.value
            ])
          }
          style={{ borderRadius: '10px' }}
          fullWidth
          size="small"
          disabled={props.disabled}
        >
          {component.options[0].listData.map(option => (
            <MenuItem
              key={option.Name + option.Value}
              value={option.Value}
              style={{
                backgroundColor: 'rgb(var(--arc-container-color))'
              }}
            >
              {option.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </InputItem>
  )
}

export default ValueList

const InformSelect = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: '10px',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow'])
  },
  '& .MuiList-root': {
    borderRadius: '10px',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow'])
  }
}))
