import CheckList from '../CheckList'
import Panel from '../Panel'
import PanelList from '../PanelList'
import Toggle from '../Toggle'
import ValueList from '../ValueList'
import Slider from '../Slider'
import type { IItem } from 'src/declarations'
import {
  FieldError,
  UseFormSetError,
  UseFormClearErrors
} from 'react-hook-form'

export interface IInputItem {
  component: IItem
  defaultValueDropdown?: string
  editable?: boolean
  disabled?: boolean
  hidden?: boolean
  onChange: (e: string[] | number[]) => void
  onBlur: (e) => void
  invalid: boolean
  setError: UseFormSetError<Record<string, string[] | number[]>>
  clearError: UseFormClearErrors<Record<string, string[] | number[]>>
  value: number | string | number[] | string[] | boolean
  name: string
}

export const InputUIItems = {
  'Number Slider': Slider,
  'Boolean Toggle': Toggle,
  Panel: Panel,
  'Panel List': PanelList,
  'Value List': ValueList,
  'Check List': CheckList
}

export interface IInputReturnData {
  name?: string
  value: string[] | number[]
}
