import { combineReducers } from 'redux'
import { outputObjectsReducer } from './outputObjects/reducer'

//Parameterspace
import { favoriteObjectsReducer } from './apps/parameterspace/favoritesObjects/reducer'
import { rootObjectsReducer } from './apps/parameterspace/rootObjects/reducer'
import { imageObjectsReducer } from './apps/parameterspace/imageObjects/reducer'
import { scrubbedObjectsReducer } from './apps/parameterspace/scrubbedObjects/reducer'
import { selectedObjectsReducer } from './apps/parameterspace/selectedOptions/reducer'
import { psProjectObjectsReducer } from './apps/parameterspace/projectInfoObjects/reducer'

export const rootReducer = combineReducers({
  outputObjects: outputObjectsReducer,
  //Parameterspace
  psFavorites: favoriteObjectsReducer,
  psRootobjects: rootObjectsReducer,
  psImages: imageObjectsReducer,
  psScrubbed: scrubbedObjectsReducer,
  psSelected: selectedObjectsReducer,
  psProjects: psProjectObjectsReducer
})

export type RootState = ReturnType<typeof rootReducer>
