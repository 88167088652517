import { ArcIconButton } from '@arc-web/components/react'
import { Tooltip, styled, useTheme } from '@mui/material'
import React from 'react'
import ZoomExtentIcon from '~assets/ZoomExtentLogo.svg'
import { unityContext } from '~pages/dashboard/components/UnityMount'
import ViewerSettingsMenu from './ViewerSettingsMenu'

interface IProps {
  showOutputSideBar: any
  showUnitySettings: boolean
  setShowUnitySettings: any
}

const handleClickZoom = () => {
  unityContext.send('Main', 'ZoomExtends')
}

const ViewerSettingsBar: React.FC<any> = ({
  showOutputSideBar,
  showUnitySettings,
  setShowUnitySettings
}: IProps) => {
  const theme = useTheme()
  return (
    <>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          bottom: '1rem',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <ViewerControlSettingsContainer theme={theme}>
          <SmallIconContainer
            id="zoomExtendsBtn"
            className={showOutputSideBar ? '' : 'collapsed'}
            onClick={handleClickZoom}
          >
            <Tooltip title="Zoom Extents" placement="bottom-start" arrow>
              <img src={ZoomExtentIcon} alt="Zoom Extent" />
            </Tooltip>
          </SmallIconContainer>
          <Tooltip title="Viewer settings" placement="bottom-start" arrow>
            <ArcIconButton
              name="settings"
              color="primary"
              onClick={() => setShowUnitySettings(!showUnitySettings)}
            />
          </Tooltip>
        </ViewerControlSettingsContainer>
      </div>
      {showUnitySettings && <ViewerSettingsMenu />}
    </>
  )
}

const ViewerControlSettingsContainer = styled('div')(({ theme }) => ({
  width: 'max-content',
  zIndex: 2,
  backgroundColor: theme.palette.background.default,
  borderRadius: '50px',
  display: 'flex',
  padding: '0 1rem 0 1rem',
  '&:hover': {
    boxShadow: 'rgb(0 0 0 / 15%) 0px 4px 16px',
    transition: 'all 0.4s'
  }
}))

const SmallIconContainer = styled('div')({
  bottom: 0,
  margin: '4px',
  borderRadius: '50%',
  width: '36px',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10,
  backgroundColor: 'rgba(255,255,255, 0.8)',
  cursor: 'pointer',
  transition: '0.1s',

  '&#outputSidebar': {
    top: 0
  },

  '&.collapsed': {
    right: '1%'
  },

  '&#zoomExtendsBtn': {
    left: '47%'
  },

  '&#captureBtn': {
    bottom: 0,
    left: '50%'
  },

  '&#zoomExtendsBtn:hover, &#captureBtn:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
  },

  '& arc-icon-button': {
    width: '30px',
    height: '30px'
  },

  '& img ': {
    width: '15px',
    height: '15px'
  }
})

export default ViewerSettingsBar
