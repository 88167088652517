import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { rootReducer } from './index'

const middleware = applyMiddleware(thunkMiddleware)

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25
})

export const store = createStore(
  rootReducer,
  undefined,
  composeEnhancers(middleware)
)
