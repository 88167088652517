import React, { useState } from 'react'
// import "@arc-web/components/dist/components/button/arc-button";
import { Button, Typography } from '@mui/material'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import CheckIcon from '@mui/icons-material/Check'

interface IProps {
  projectId: string
}

export const CopyLink: React.FC<IProps> = (props: IProps) => {
  const [copied, setCopied] = useState(false)

  const copy = e => {
    e.preventDefault()
    navigator.clipboard.writeText(e.target.innerText)
    setCopied(true)
  }

  return (
    <div className="App">
      {!copied ? (
        <>
          <Button
            endIcon={<ContentCopyRoundedIcon />}
            variant="outlined"
            size="large"
            onClick={copy}
            style={{ marginBottom: 20, marginTop: 15, textTransform: 'none' }}
          >
            {window.location.host}
            {props.projectId}
          </Button>
        </>
      ) : (
        <>
          <Button
            endIcon={<CheckIcon />}
            variant="outlined"
            size="large"
            style={{ marginBottom: 20, marginTop: 15, marginRight: 5 }}
          >
            {window.location.host}
            {props.projectId}
          </Button>
          <Typography variant="caption">Copied to clipboard.</Typography>
        </>
      )}
    </div>
  )
}
