import { ArcIconButton } from '@arc-web/components/dist/components/icon-button/react'
import { ArcNavbar } from '@arc-web/components/dist/components/navbar/react'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { Providers } from '@microsoft/mgt-element'
import { Msal2Provider } from '@microsoft/mgt-msal2-provider'
import { Login } from '@microsoft/mgt-react'
import type { Theme } from '@mui/material/styles'
import { styled, useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { loginRequest, msal } from 'src/lib/auth/auth'
import InformLogoBlack from '~assets/Logo_InForm_Black.svg'
import InformLogoWhite from '~assets/Logo_InForm_White.svg'
import { onClickUrl } from '~utils/onClickRedirect'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { IconButton, Tooltip } from '@mui/material'
const PREFIX = 'AppBar'

const classes = {
  userName: `${PREFIX}-userName`
}

const StyledArcNavbar = styled(ArcNavbar)(({ theme }: { theme: Theme }) => ({
  height: '2.7rem',
  [`& .${classes.userName}`]: {
    borderLeft: `2px solid ${theme.palette.background.paper}`
  }
}))

Providers.globalProvider = new Msal2Provider({
  publicClientApplication: msal,
  scopes: loginRequest.scopes
})

const AppBar = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <StyledArcNavbar
      arup={true}
      home="/"
      slot="nav"
      tabs={3}
      logo={theme.palette.mode === 'light' ? InformLogoBlack : InformLogoWhite}
    >
      <AuthenticatedTemplate>
        <Tooltip title="Project Overview">
          <ArcIconButton
            name="grid"
            label="Project Selection"
            onClick={() => navigate('/')}
          ></ArcIconButton>
        </Tooltip>
        <Tooltip title="Documentation">
          <IconButton
            onClick={onClickUrl(
              'https://lively-water-096984503.1.azurestaticapps.net'
            )}
          >
            <HelpOutlineIcon></HelpOutlineIcon>
          </IconButton>
        </Tooltip>
      </AuthenticatedTemplate>
      <Login></Login>
    </StyledArcNavbar>
  )
}

export default AppBar
