import type { Action, Reducer } from 'redux'

import type { KnownAction } from './actions'
import type { PsOption } from '../projectInfoObjects/types'

export interface IScrubbedObjects {
  scrubbedObject: PsOption[] | null
  initCount: number
}

export const scrubbedObjectsReducer: Reducer<IScrubbedObjects> = (
  state: IScrubbedObjects = { scrubbedObject: null, initCount: 0 },
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction

  switch (action.type) {
    case 'SET_SCRUBBED_OBJECT':
      return { ...state, scrubbedObject: action.scrubbedObject }
    case 'SET_INIT_COUNT':
      return { ...state, initCount: action.initCount }
    default:
      return state
  }
}
