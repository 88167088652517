import React from 'react'
import { Typography } from '@mui/material'
import './SpotlightNumber.styles.css'
import HighlightRoundedIcon from '@mui/icons-material/HighlightRounded'
import type { SpotlightNumberProps } from './SpotlightNumber.types'

export interface IProps {
  data: SpotlightNumberProps
}

const SpotlightNumber: React.FC<IProps> = (props: IProps) => {
  const { titles, values, units } = props.data
  if (!titles || !values || !units) {
    return null
  }

  return (
    <div className="spotlight">
      <div style={{ display: 'flex' }}>
        <HighlightRoundedIcon
          style={{ width: '15px', marginRight: 4 }}
        ></HighlightRoundedIcon>
        <Typography
          variant="body1"
          style={{ fontSize: '0.7rem', lineHeight: '2.4', fontWeight: 500 }}
        >
          Highlight
        </Typography>
      </div>
      {values.map((value, i) => {
        return (
          <div key={i} style={{ display: 'flex' }}>
            <Typography
              variant="caption"
              style={{
                width: '-webkit-fill-available',
                lineHeight: '2.4',
                fontSize: '0.7rem'
              }}
            >
              {titles[i] ? titles[i] : ''}:
            </Typography>
            <Typography
              variant="h6"
              style={{ marginLeft: '5px', fontSize: '1rem' }}
            >
              {value}
            </Typography>
            <Typography variant="caption" style={{ marginLeft: '5px' }}>
              {units[i] ? units[i] : '-'}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}
export default SpotlightNumber
