import { unityContext } from '~pages/dashboard/components/UnityMount'
import type { LayerVisibility, LineThickness } from './types'
import type { ListOutput, Output } from '~api/inform/types'
import { ListType, OutputType } from '~api/inform/types'
import type { OutputAndUri } from '~pages/outputs/types/OutputItem'

export const setLayerStateUnity = (layerState: LayerVisibility) => {
  unityContext.send(
    'Main',
    'ChangeObjectVisibilityJson',
    JSON.stringify(layerState)
  )
}

export const setLayerStateContextUnity = (layerState: LayerVisibility) => {
  unityContext.send(
    'ContextObjectManager',
    'ChangeObjectVisibilityJson',
    JSON.stringify(layerState)
  )
}

export const InitLayerStatesUnity = (layerStates: LayerVisibility[]) => {
  unityContext.send(
    'Main',
    'InitChangeObjectVisibilityJson',
    `{Packages:${JSON.stringify(layerStates)}}`
  )
  unityContext.send(
    'ContextObjectManager',
    'InitChangeObjectVisibilityJson',
    `{Packages:${JSON.stringify(layerStates)}}`
  )
}

export const setLineThicknessUnity = (thickness: LineThickness) => {
  unityContext.send(
    'Main',
    'ChangeLineWeightByParamNameJson',
    JSON.stringify(thickness)
  )
}

export const initLineThicknessesUnity = (thicknesses: LineThickness[]) => {
  unityContext.send(
    'Main',
    'InitChangeLineWeightByParamNameJson',
    `{Packages:${JSON.stringify(thicknesses)}}`
  )
}

export const importGeometryUnity = (outputs: Record<string, Output>) => {
  const geometry = outputToUnity(outputs)
  unityContext.send('Main', 'ImportGltfFromUri', JSON.stringify(geometry))
}

export const importEmptyUnity = () => {
  const geometry = {
    Output: {
      rhinoType: 'Mesh',
      paramName: ''
    },
    Uri: ''
  }
  unityContext.send('Main', 'ImportGltfFromUri', JSON.stringify(geometry))
}

export const importContextUnity = (outputs: Record<string, Output>) => {
  const geometry = outputToUnity(outputs)
  unityContext.send('Main', 'ImportContext', JSON.stringify(geometry))
}

const outputToUnity = (outputs: Record<string, Output>) => {
  const names = Object.keys(outputs)
  const geometryList = [] as OutputAndUri[]
  Object.values(outputs).map((o, i) => {
    if (o.dataType === OutputType.Mesh) {
      const mesh = o as ListOutput
      const geometry = {
        Output: {
          rhinoType: 'Mesh',
          paramName: names[i].replace('analysis', 'Analysis')
        },
        Uri:
          o.listType === ListType.List
            ? (mesh.outputData.value as string)
            : (mesh.outputData[0].value as string)
      }
      geometryList.push(geometry)
    }
  })
  return geometryList
}
