import { FormControl, FormControlLabel, FormGroup, Switch } from '@mui/material'
import React from 'react'
import { InputItem } from './styles/styles'
import type { IInputItem } from './types/types'

const Toggle: React.FC<IInputItem> = (props: IInputItem) => {
  const component = props.component

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    props.onChange([checked.toString()])
  }

  return (
    <InputItem>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="right"
            control={
              <Switch
                color="primary"
                size="small"
                checked={props.value as boolean}
                onChange={handleChange}
                disabled={props.disabled}
              ></Switch>
            }
            label={component.name}
            disableTypography
          />
        </FormGroup>
      </FormControl>
    </InputItem>
  )
}

export default Toggle
