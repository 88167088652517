import { useState, useEffect } from 'react'
import '@arc-web/components/dist/components/button/arc-button'
import { Box, Modal, Typography } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import type { ChildrenType } from '~utils/utilityTypes'

type BaseModalProps = {
  open: boolean
  close: boolean
  title: string
} & ChildrenType

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  height: 'fit-content',
  background: 'white',
  borderRadius: '8px',
  padding: 4
}

const BaseModal = (props: BaseModalProps) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (props.open) {
      setOpen(true)
    }
  }, [props.open])

  const onClicked = event => {
    event.preventDefault()
    setOpen(!open)
  }

  return (
    <Modal
      open={open}
      //   style={style}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">{props.title}</Typography>
          <CloseRoundedIcon
            style={{ cursor: 'pointer' }}
            onClick={onClicked}
          ></CloseRoundedIcon>
        </div>

        {props.children}
      </Box>
    </Modal>
  )
}

export default BaseModal
