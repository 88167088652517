import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './ProjectCard.css'

interface IProps {
  projectId: string
  title: string
  projectImage?: string | undefined
  createdBy: string
  creatorId: string
  createdByImage?: string | undefined
  allData: any
  public?: string | undefined
  description?: string | undefined
  jobNr?: string | undefined
  version: string | undefined
  ghPath?: string
  color?: string
}

export const ProjectHighlightCard = (props: IProps) => {
  const navigator = useNavigate()

  const onClickNewProject = e => {
    navigator('/project/' + props.projectId)
    window.location.reload()
  }

  return (
    <div className="projectCard" style={{ minHeight: '200px' }}>
      <img
        onClick={onClickNewProject}
        className="projectCardImage"
        height="200px"
        width="100%"
        src={props.projectImage}
        alt={props.title}
      ></img>

      <Typography
        variant="subtitle1"
        style={{
          fontWeight: '500',
          lineHeight: '0.5',
          marginTop: '5%',
          color: 'rgba(var(--arc-white-000), 0.9)'
        }}
      >
        {props.title}
      </Typography>
      <Typography
        variant="caption"
        style={{ color: 'rgba(var(--arc-white-000), 0.9)' }}
      >
        {props.description}
      </Typography>
    </div>
  )
}
