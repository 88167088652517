import React from 'react'
import { styled } from '@mui/system'
import { Tooltip, Typography } from '@mui/material'
import type { DataListProps } from './DataList.types'

export interface IProps {
  data: DataListProps
}

const DataList: React.FC<IProps> = (props: IProps) => {
  const { titles, values, units } = props.data

  if (!titles || !values) {
    return null
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      {titles.map((title, i) => {
        return (
          <div
            key={i}
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Tooltip title={title}>
              <Typography
                variant="caption"
                color="text.secondary"
                style={{
                  width: '70%',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
              >
                {title ? title.replace(/^"(.+(?="$))"$/, '$1') : ''}:
              </Typography>
            </Tooltip>
            <div style={{ display: 'flex' }}>
              <Tooltip
                title={
                  title +
                  ' ' +
                  values[i] +
                  ' ' +
                  (units ? units[i].replace(/^"(.+(?="$))"$/, '$1') : '-')
                }
              >
                <Typography
                  variant="body2"
                  style={{
                    marginLeft: '4px',
                    marginRight: '10px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    fontWeight: 500
                  }}
                >
                  {values[i].toString().replace(/^"(.+(?="$))"$/, '$1')}
                </Typography>
              </Tooltip>
              <Typography
                variant="caption"
                style={{
                  position: 'relative',
                  right: 0,
                  width: '30px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              >
                {units ? units[i].replace(/^"(.+(?="$))"$/, '$1') : '-'}
              </Typography>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default DataList
