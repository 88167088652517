import { styled } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import Unity, { UnityContext } from 'react-unity-webgl'

export const unityContext = new UnityContext({
  loaderUrl:
    process.env.PUBLIC_URL +
    '/unitybuild/InformDemo/Arup-Inform-Viewer.loader.js',
  dataUrl:
    process.env.PUBLIC_URL + '/unitybuild/InformDemo/Arup-Inform-Viewer.data',
  frameworkUrl:
    process.env.PUBLIC_URL +
    '/unitybuild/InformDemo/Arup-Inform-Viewer.framework.js',
  codeUrl:
    process.env.PUBLIC_URL + '/unitybuild/InformDemo/Arup-Inform-Viewer.wasm',
  webglContextAttributes: {
    preserveDrawingBuffer: true
  }
})

// Replace with our actual Unity project
const UnityMount = () => {
  // Built-in event invoked when the Unity canvas is ready to be interacted with.
  const handleOnUnityCanvas = useCallback(
    canvas => canvas.setAttribute('role', 'unityCanvas'),
    []
  )
  // When the component is mounted, we'll register some event listener.
  useEffect(() => {
    return () => {
      unityContext.removeAllEventListeners()
    }
  }, [handleOnUnityCanvas])

  return <UnityCanvasComponent unityContext={unityContext} tabIndex={1} />
}

export default UnityMount

const UnityCanvasComponent = styled(Unity)({
  width: '100%',
  height: '100%'
})
