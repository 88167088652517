import React from 'react'
// import React, { useRef } from "react";
import '@arc-web/components/dist/components/button/arc-button'
import { TextField } from '@mui/material'
import { InputItem } from './styles/styles'
import type { IInputItem } from './types/types'

const PanelList: React.FC<IInputItem> = (props: IInputItem) => {
  const component = props.component
  const defaultVal = props.value as string[]

  const [value, setValue] = React.useState(
    defaultVal && defaultVal.join('\r\n')
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleSubmit = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const list = event.target.value.split('\n')

    const typedList = [] as any
    if (component.typeHint === 'Number' || component.typeHint === 'Integer') {
      for (let i = 0; i < list.length; i++) {
        const element = list[i]
        typedList.push(Number(element))
      }
    } else {
      for (let i = 0; i < list.length; i++) {
        const element = list[i]
        typedList.push(element)
      }
    }

    props.onChange(typedList)
  }

  return (
    <InputItem>
      <TextField
        onChange={handleChange}
        onBlur={handleSubmit}
        size="small"
        value={value}
        label={component.name}
        multiline
        maxRows={10}
        disabled={props.disabled}
      ></TextField>
    </InputItem>
  )
}

export default PanelList
