import { useMsal } from '@azure/msal-react'
import {
  Box,
  Button,
  ClickAwayListener,
  Step,
  StepLabel,
  Stepper
} from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useState } from 'react'

import AddNewForm from '~components/form/ProjectForm'
import FileUpload from '../upload/FileUpload'

const AddNew = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const { accounts } = useMsal()
  const [inputs, setInputs] = useState({
    createdBy: accounts[0]?.name ?? accounts[0]?.username
  })
  const [required, setRequired] = useState<boolean>()

  const handleInputs = e => {
    setInputs(e)

    //To not have annoying ts errors
    setCompleted('')
  }

  useEffect(() => {
    if (inputs) {
      if (inputs['title']) {
        setRequired(true)
      }
    }
  }, [inputs])

  const steps = ['Project data', 'Upload project']
  const stepContent = step => {
    switch (step) {
      case 0:
        return <AddNewForm values={inputs} onChangeInput={handleInputs} />
      case 1:
      default:
        return <FileUpload projectJson={inputs} />
    }
  }

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleClickAway = () => {
    // props.addNewIsOpen(false)
  }

  return (
    <Popup>
      <ClickAwayListener onClickAway={handleClickAway}>
        <PopupBox>
          <Stepper activeStep={activeStep} alternativeLabel color="error">
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {stepContent(activeStep)}

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="error"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === steps.length - 1 ? (
              ''
            ) : required ? (
              <Button onClick={handleNext} sx={{ mr: 1 }} color="error">
                Next
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                disabled
                sx={{ mr: 1 }}
                color="error"
              >
                Next
              </Button>
            )}
          </Box>
        </PopupBox>
      </ClickAwayListener>
    </Popup>
  )
}
export default AddNew

const Popup = styled('div')({
  // position: "fixed",
  display: 'flex',
  justifyContent: 'space-evenly',
  flexDirection: 'column',
  background: '#00000050',
  width: '100%',
  height: 'calc(100% - 48px)',
  top: '0',
  left: '0'
})

const PopupBox = styled('div')({
  position: 'relative',
  // width: "50%",
  minWidth: '55%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',

  // height: "100vh",
  // max-height: 70vh;
  // marginTop: "0%",
  background: '#fff',
  borderRadius: '4px',
  padding: '2rem',
  paddingLeft: '3rem',
  paddingRight: '3rem',
  paddingTop: '3rem',
  border: '1px solid #999',
  overflow: 'auto'
})
