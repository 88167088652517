import { ArcButton, ArcIcon } from '@arc-web/components/react'
import CircularProgress from '@mui/material/CircularProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import type { UseMutationResult } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import type { IItem } from 'src/declarations'
import type { RhinoComputeResponse, RhinoComputeType } from '~api/inform/types'
import { OutputType } from '~api/inform/types'
import { InformCard } from '~components/card/BaseCard'
import {
  LoaderContainer,
  SideBarBottomContainer,
  SideBarContainer,
  SideBarTopContainer
} from '~pages/dashboard/Dashboard.styles'
import { useInputFormContext } from '~pages/dashboard/useInputFormContext'
import LayerStatesForm from './LayerStatesForm'
import type { IInputItem } from './components/types/types'
import { InputUIItems } from './components/types/types'
import { Typography } from '@mui/material'

interface IProps {
  userRole: string | undefined
  onSubmit: SubmitHandler<Record<string, string[] | number[]>>
  computeMutation: UseMutationResult<
    RhinoComputeResponse,
    unknown,
    RhinoComputeType,
    unknown
  >
  outputData?: RhinoComputeResponse
  components: any
}

const InputSidebar = ({
  userRole,
  onSubmit,
  computeMutation,
  outputData,
  components
}: IProps) => {
  const [tabValue, setTabValue] = useState<number>(0)
  const [meshLayers, setMeshLayers] = useState<string[]>()

  const { inputsForm } = useInputFormContext()

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitted },
    getValues,
    reset,
    setError,
    clearErrors
  } = inputsForm

  /*
  Local state to keep track of the selected Tab in the sidebar.
  */
  const handleChangeTab = (event, newValue) => {
    // newValue as index
    setTabValue(newValue)
  }

  /**
   * Get all the mesh layers from the outputData and set initial.
   */
  useEffect(() => {
    if (outputData) {
      const layers = Object.entries(outputData.outputs).map(([k, v]) => ({
        layer: k,
        type: v.dataType
      }))

      const meshes = layers.filter(layer => layer.type === OutputType.Mesh)
      if (meshes) {
        setMeshLayers(meshes.map(o => o.layer))
      }
    }
  }, [outputData])

  /*
  On mounting of the component, directly submit the default values to compute the default geometry.
  */
  useEffect(() => {
    onSubmit(getValues())
  }, [])

  /*
  If submission is successful, reset the form, so that the state of the button turns to disabled.
  */
  // useEffect(() => {
  //   console.log(isSubmitted)

  //   if (isSubmitted) {
  //     console.log(isSubmitted)
  //     reset(getValues())
  //   }
  // }, [isSubmitted])

  //THIS IS A TEMPORARY FIX UNTIL WE FIGURE OUT WHY THE USEFORM COMPONENT KEEPS RESETTING AFTER SUBMITTING.
  const handleSubmitInputs = () => {
    onSubmit(getValues())
    reset(getValues())
  }

  return (
    <SideBarContainer>
      <Tabs
        value={tabValue}
        variant="fullWidth"
        onChange={handleChangeTab}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        style={{ minHeight: '32px', height: '32px' }}
      >
        <Tab
          label="Inputs"
          icon={
            <ArcIcon
              name="equalizer"
              size="small"
              style={{ marginRight: '5px' }}
            ></ArcIcon>
          }
          iconPosition="start"
          style={{ minHeight: '32px', height: '32px' }}
        />
        <Tab
          label="Layers"
          icon={
            <ArcIcon
              name="layers"
              size="small"
              style={{ marginRight: '5px' }}
            ></ArcIcon>
          }
          iconPosition="start"
          style={{ minHeight: '32px', height: '32px' }}
        />
      </Tabs>
      <SideBarTopContainer>
        <div>
          {tabValue === 0 &&
            components.inputComponents &&
            components.defaultInputNames &&
            components.defaultInputValues &&
            components.inputComponents.map((component, i) => (
              <InformCard
                key={component.name + i}
                name={component.name}
                userRole={userRole ? userRole : ''}
              >
                {component.groups[0].items.map((item: IItem, i) => {
                  const InputItem = InputUIItems[
                    item.type
                  ] as React.FC<IInputItem>
                  return (
                    <>
                      <Controller
                        key={item.param + i}
                        name={item.param}
                        control={control}
                        rules={{
                          required: true
                        }}
                        defaultValue={components.defaultInputValues[item.param]}
                        render={({
                          field: { onChange, onBlur, value, name },
                          fieldState: { invalid }
                        }) => (
                          <InputItem
                            onChange={onChange}
                            onBlur={onBlur}
                            component={item}
                            value={value}
                            disabled={computeMutation.isLoading}
                            defaultValueDropdown={
                              components.defaultInputNames[item.param]
                            }
                            name={name}
                            invalid={invalid}
                            setError={setError}
                            clearError={clearErrors}
                          ></InputItem>
                        )}
                      />
                      {errors[item.param]?.type === 'required' && (
                        <p role="alert">Field is required</p>
                      )}
                      {errors[item.param] &&
                        errors[item.param]?.type === 'custom' && (
                          <Typography color="error" variant="caption">
                            {errors[item.param]?.message}
                          </Typography>
                        )}
                    </>
                  )
                })}
              </InformCard>
            ))}
          {tabValue === 1 &&
            (meshLayers ? (
              <LayerStatesForm meshLayers={meshLayers}></LayerStatesForm>
            ) : (
              <LoaderContainer>
                <CircularProgress />
              </LoaderContainer>
            ))}
        </div>
      </SideBarTopContainer>
      <div style={{ position: 'relative' }}>
        <SideBarBottomContainer style={{ backgroundColor: 'white' }}>
          <ArcButton
            onClick={() => reset()}
            type="outlined"
            size="small"
            color="secondary"
            disabled={
              !isDirty || computeMutation.isLoading || computeMutation.isError
            }
            style={{ width: '25%' }}
          >
            Reset
          </ArcButton>
          <ArcButton
            onClick={handleSubmitInputs}
            size="small"
            color="primary"
            loading={computeMutation.isLoading && !computeMutation.isError}
            disabled={
              (!isDirty && !computeMutation.isLoading) ||
              Object.keys(errors).length > 0
            }
            style={{ width: '70%' }}
          >
            Submit Calculation
          </ArcButton>
        </SideBarBottomContainer>
      </div>
    </SideBarContainer>
  )
}

export default InputSidebar
