import { useToken } from '~api/useToken'
import { getStaticGeometry } from './api'
import { useQuery } from '@tanstack/react-query'

export const useStaticGeometry = (projectId?: string) => {
  const { getToken } = useToken()
  const { data, isLoading, error } = useQuery({
    queryKey: ['staticGeometry', projectId],
    queryFn: async () => getStaticGeometry(projectId ?? '', await getToken()),
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity
  })
  return {
    staticGeometry: data,
    isLoading,
    error
  }
}
