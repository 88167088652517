import type { IInput, IOutput } from 'src/declarations'
import { IOutputComponent } from 'src/declarations'
import type { IInputReturnData } from '~pages/inputs/components/types/types'

export interface InitialInput {
  projectId: string
  updated: string
  inputs: IInput[]
  outputs: IOutput[]
}

export type RhinoComputeType = {
  projectId: string
  inputs: IInputReturnData[]
  ghFilePath: string
}

export interface RhinoComputeResponse {
  id: string
  updated: { $date: Date }
  projectId: string
  calculationTime: number
  inputs: Record<string, object>
  outputs: Record<string, Output>
  version: number
  errors: string[]
  warnings: string[]
}

export interface Output {
  dataType: OutputType
  listType: ListType
}

export interface TreeOutput extends Output {
  outputData: OutputData[]
}

export interface ListOutput extends Output {
  outputData: OutputData
}

export enum OutputType {
  Text,
  Number,
  Curve,
  Mesh
}

export enum ListType {
  List,
  Tree
}

export interface OutputData {
  path: string
  value: number[] | string[] | string
}

export interface PrimitiveNumber extends OutputData {
  value: number[]
}

export interface PrimitiveString extends OutputData {
  value: string[]
}

export interface GeometryOutput extends OutputData {
  value: string
}

export interface ProjectInfo {
  _id: string
  projectId: string
  modelFilePath: string
  title: string
  description: string
  createdBy: string
  creatorId: string
  featured: boolean
  region: string[]
  public: string
  tags: string[]
  expectedAnalysisTime: number
  __v: number
}

export interface Version {
  createdAt: string
  lastUpdated: string
  comment: string
}

export interface OutputResponseUppercase {
  Key: string
  Value: object
}

export interface OutputResponse {
  key: string
  value: string[]
  Key: string
  Value: string[]
}

export interface InFormError {
  errors: string[]
  warnings: string[]
}

export class InFormError implements InFormError {
  public code: number
  public errors: string[]
  public warnings: string[]
  constructor(errors: string[], warnings: string[] = [], code = 1.0) {
    this.errors = errors
    this.warnings = warnings
    this.code = code
  }
}
