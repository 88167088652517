import type { Action, Reducer } from 'redux'
import type { KnownAction } from './actions'
import { TOGGLE_ANALYSIS_NAME } from './actions'

export interface IBase {
  analysisToggled: string | null
}

export const initialState = {
  analysisToggled: null
}

export const outputObjectsReducer: Reducer<IBase> = (
  state: IBase = initialState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction
  switch (action.type) {
    case TOGGLE_ANALYSIS_NAME:
      return {
        ...state,
        analysisToggled: action.name
      }
    default:
      return state
  }
}
