import type { LinearProgressProps } from '@mui/material'
import {
  Box,
  LinearProgress,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import React from 'react'
import type { ProgressBarProps } from './ProgressBar.types'

function LinearProgressWithLabel(
  props: LinearProgressProps & {
    value: number
    maxvalue: number
    absvalue: number
    unit: string
    progressTitle: string
  }
) {
  const theme = useTheme()
  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
        >
          <Typography variant="caption" color="text.secondary">
            {props.progressTitle}
          </Typography>
        </div>
        <div style={{ display: 'flex', marginLeft: '2px' }}>
          <Tooltip title={props.absvalue}>
            <Typography
              variant="body2"
              style={{
                fontWeight: 500,
                marginRight: '10px',
                marginLeft: '4px',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {props.absvalue}
            </Typography>
          </Tooltip>
          <Typography variant="caption" style={{ width: '30px' }}>
            {props.unit}
          </Typography>
        </div>
      </div>
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between'
        }}
      >
        <Box
          component="div"
          sx={{
            width: '85%',
            mr: 1
          }}
        >
          <Tooltip
            title={`${props.value}% ,  Max ${props.progressTitle}:  ${props.maxvalue} ${props.unit} `}
          >
            <LinearProgress
              variant="determinate"
              {...props}
              sx={{
                backgroundColor: theme.palette.background.default,
                height: '6px',
                borderRadius: '3px'
              }}
            />
          </Tooltip>
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            width: '15%'
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >{`${props.value}%`}</Typography>
        </div>
      </Box>
    </React.Fragment>
  )
}

export interface IProps {
  data: ProgressBarProps
}

const ProgressBar: React.FC<IProps> = (props: IProps) => {
  const { titles, values, maxima, units } = props.data
  if (!titles || !values || !maxima || !units) {
    return null
  }

  return (
    <div>
      {values.map((value, i) => {
        const val = Math.round((value / maxima[i]) * 100)
        return (
          <LinearProgressWithLabel
            key={titles[i]}
            maxvalue={maxima[i]}
            variant="determinate"
            value={val}
            absvalue={value}
            unit={units[i]}
            progressTitle={titles[i]}
          ></LinearProgressWithLabel>
        )
      })}
    </div>
  )
}

export default ProgressBar
