import type { ParameterSpaceActions } from './actions'
import {
  FETCH_PROJECT_BY_ID,
  FETCH_PROJECTS_BY_DATE,
  FETCH_PROJECTS_BY_USER_ID
} from './actions'
import type { PsBase } from './types'

export const initialState: PsBase = {
  projectsByDate: null,
  projectsByUserId: null,
  selectedProject: null
}

export const psProjectObjectsReducer = (
  state = initialState,
  action: ParameterSpaceActions
): PsBase => {
  switch (action.type) {
    case FETCH_PROJECTS_BY_DATE: {
      return {
        ...state,
        projectsByDate: {
          projects: action.data?.projects,
          totalPageCount: action.data?.totalPageCount,
          totalProjectCount: action.data?.totalProjectCount
        }
      }
    }
    case FETCH_PROJECT_BY_ID: {
      return {
        ...state,
        selectedProject: action.data
      }
    }
    case FETCH_PROJECTS_BY_USER_ID: {
      return {
        ...state,
        projectsByUserId: {
          projects: action.data?.projects,
          totalProjectCount: action.data?.totalProjectCount,
          totalPageCount: action.data?.totalPageCount
        }
      }
    }
    default:
      return state
  }
}
