import React, { useState } from 'react'
// import React, { useRef } from "react";
import '@arc-web/components/dist/components/button/arc-button'
import {
  TextField,
  Switch,
  FormControlLabel,
  FormGroup,
  Typography
} from '@mui/material'

export interface ILabel {
  title: string
  description: string
}

interface IProps {
  component: any
  labelDefault: any
  visibleDefault: boolean
  isVisible: (event: any) => void
  label: (event: ILabel) => void
}

type PropsType = IProps

const EditLabels: React.FC<any> = (props: PropsType) => {
  const component = props.component

  const [visible, setVisible] = useState(props.visibleDefault)
  const handleVisible = () => {
    setVisible(!visible)
    props.isVisible(!visible)
  }

  const [label, setLabel] = useState<ILabel>(props.labelDefault)
  const handleLabelTitle = e => {
    e.preventDefault()
    const newLabel = { title: e.target.value, description: label.description }
    setLabel(newLabel)
    props.label(newLabel)
  }

  const handleLabelDescription = e => {
    e.preventDefault()
    const newLabel = { title: label.title, description: e.target.value }
    setLabel(newLabel)
    props.label(newLabel)
  }

  return (
    <>
      <FormGroup
        style={{
          marginLeft: '0.5rem',
          marginTop: !visible ? '-20px' : '0px',
          marginBottom: '10px'
        }}
      >
        <FormControlLabel
          control={
            <Switch
              defaultChecked
              size="small"
              sx={{ color: 'rgb(var(--arc-grey-060))' }}
              checked={visible}
              onClick={handleVisible}
            />
          }
          label={
            <Typography
              style={{ fontSize: '0.7rem', color: 'rgb(var(--arc-grey-060))' }}
            >
              Visible?
            </Typography>
          }
        />
      </FormGroup>
      <TextField
        variant="outlined"
        size="small"
        label="title"
        style={{ marginBottom: '5px' }}
        value={label.title}
        onChange={handleLabelTitle}
      ></TextField>
      <TextField
        variant="outlined"
        size="small"
        label="description"
        style={{ marginBottom: '5px' }}
        multiline
        value={label.description}
        onChange={handleLabelDescription}
      ></TextField>
    </>
  )
}

export default EditLabels
