import { useMsal } from '@azure/msal-react'
import { getAccessToken } from 'src/lib/auth/auth'

export function useToken() {
  const { instance, accounts } = useMsal()
  return {
    async getToken() {
      return getAccessToken(accounts[0], instance)
    }
  }
}
