import { useMsal } from '@azure/msal-react'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './ProjectCard.css'
import { CardBar } from './ProjectCardBar'

interface IProps {
  projectId: string
  title: string
  projectImage?: string | undefined
  createdBy: string
  creatorId: string
  createdByImage?: string | undefined
  allData: any
  public?: string | undefined
  description?: string | undefined
  jobNr?: string | undefined
  version: string | undefined
  ghPath?: string
  color?: string
}

export const ProjectCard = (props: IProps) => {
  const navigator = useNavigate()

  const [avatar, setAvatar] = useState() as any
  const [creator, setCreator] = useState(null) as any
  const { instance, accounts, inProgress } = useMsal()

  const onClickNewProject = e => {
    navigator('/project/' + props.projectId)
    window.location.reload()
  }

  return (
    <div className="projectCard" style={{ minHeight: '200px' }}>
      <img
        onClick={onClickNewProject}
        className="projectCardImage"
        height="200px"
        width="100%"
        src={props.projectImage}
        alt={props.title}
      ></img>
      {props.creatorId ? (
        <CardBar
          avatar={avatar}
          name={creator ? creator.displayName : props.createdBy}
          projectId={props.projectId}
          creatorId={props.creatorId}
          allData={props.allData}
          public={props.public !== 'Arup Internal'}
          ghPath={props.ghPath}
          title={props.title}
        ></CardBar>
      ) : (
        ''
      )}

      <Typography
        variant="subtitle1"
        color="error"
        component="div"
        style={{ fontWeight: '500', lineHeight: '0.5', marginTop: '5%' }}
      >
        {props.title}
      </Typography>
      <Typography variant="caption">{props.description}</Typography>
    </div>
  )
}
