import { styled } from '@mui/material'

export const BatchComputeContainer = styled('div')({
  height: 'max(3rem,48px)',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  paddingRight: '1rem'
})

export const SideBarTopContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  // height: 'calc(100% - 60px)',
  padding: '0.2rem 1rem 1rem 1rem',
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  height: 'calc(100% - 60px)',
  overflow: 'scroll'
})

export const SideBarBottomContainer = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '60px',
  padding: '1rem',
  display: 'flex',
  bottom: 0,
  right: 0,
  justifyContent: 'space-between',
  zIndex: 200
})

export const SideBarContainer = styled('div')({
  backgroundColor: 'rgb(var(--arc-container-color))',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%'
})

export const LoaderContainer = styled('div')({
  width: '15%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: '80px',
  bottom: 0,
  left: 0
})

export const LayerItemContainer = styled('div')({
  display: 'flex',
  '& .layerName': {
    width: '80%',
    margin: 0,
    padding: 0,
    paddingTop: '0.500rem',

    wordBreak: 'break-all'
  },

  '& .layerSetting': {
    display: 'flex',
    alignItems: 'center'
  },

  '& .layerSetting span': {
    display: 'flex'
  },

  '& .layerSetting svg': {
    height: '20px',
    cursor: 'pointer',
    paddingTop: '10px'
  },

  '& .layerSetting svg:hover': {
    color: 'rgb(var(--arc-red-060))',
    transition: '0.2s'
  }
})
