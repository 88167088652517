import { useToken } from '~api/useToken'
import { getProjectByProjectId } from './api'
import { useQuery } from '@tanstack/react-query'

export const useProjectInfo = (projectId?: string) => {
  const { getToken } = useToken()
  const { data, isLoading, error } = useQuery({
    queryKey: ['projectInfo', projectId],
    queryFn: async () =>
      getProjectByProjectId(projectId ?? '', await getToken()),
    enabled: !!projectId,
    staleTime: 20000
  })

  return {
    projectInfo: data ? data : undefined,
    isLoading,
    error
  }
}
