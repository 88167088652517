import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Card, Chip, Typography } from '@mui/material'
import { useState } from 'react'
import type { ChildrenType } from '~utils/utilityTypes'
import './BaseCard.css'

type InformCardProps = {
  name: string
  ifEdit?: boolean
  hideHeader?: boolean
  toggleEditUI?: any
  subtitle?: string
  userRole?: string
  height?: string
  width?: string
  border?: string
  color?: string
  chip?: string
  updating?: boolean
  hidden?: boolean
} & ChildrenType

export const InformCard = (props: InformCardProps) => {
  const [collapseCard, setCollapseCard] = useState<boolean>(false)

  const onMouseOver = () => {
    // setMouseOver(true);
    //
  }

  const onMouseLeave = () => {
    // setMouseOver(false);
    //
  }

  const onClickArrow = () => {
    setCollapseCard(!collapseCard)
  }

  return (
    <Card
      id={'top'}
      style={{
        height: 'max-content',
        width: props.width ? props.width : 'auto',
        marginBottom: '1rem',
        border: props.border ? props.border : '1px solid rgb(190, 190, 190)',
        padding: '1.2rem',
        boxShadow: 'none',
        transition: 'all 0.4s',
        opacity: props.hidden ? 0 : 1,
        borderRadius: '10px',
        overflow: 'hidden'
      }}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {!props.hideHeader && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'top',
            transition: 'all 0.4s',
            padding: '0',
            opacity: props.hidden ? 0 : 1,
            marginBottom: '1rem'
          }}
        >
          <div style={{ gap: '0rem' }}>
            <Typography variant="h5">{props.name}</Typography>
            <Typography
              variant="subtitle1"
              // color="text.secondary"
              color="primary"
            >
              {props.subtitle}
            </Typography>
          </div>

          <div style={{ display: 'flex' }}>
            {props.chip && !props.updating && (
              <Chip
                label={props.chip}
                style={{
                  height: '15px',
                  marginTop: '2px',
                  backgroundColor: props.color,
                  color: 'white'
                }}
              ></Chip>
            )}
            {props.updating && (
              <Chip
                label="updating"
                style={{
                  height: '15px',
                  marginTop: '2px'
                }}
              ></Chip>
            )}
            {!collapseCard ? (
              <KeyboardArrowDownIcon
                style={{ cursor: 'pointer' }}
                onClick={onClickArrow}
              ></KeyboardArrowDownIcon>
            ) : (
              <KeyArrowUpIcon
                style={{ cursor: 'pointer' }}
                onClick={onClickArrow}
              ></KeyArrowUpIcon>
            )}
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          opacity: collapseCard ? 0 : 1,
          maxHeight: collapseCard ? 0 : '800px',
          transition: 'all 0.3s'
        }}
      >
        {props.children}
      </div>
    </Card>
  )
}
