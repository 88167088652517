import { ArcIconButton, ArcSpinner } from '@arc-web/components/react'
import type { SnackbarOrigin } from '@mui/material'
import { Button, Snackbar, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import React, { useEffect, useMemo } from 'react'
import LinearProgressWithLabel from '~components/progress/LinearProgressWithLabel'
import { AnalysisState } from '~components/types'

interface State extends SnackbarOrigin {
  open: boolean
}

interface IProps {
  analyisState: AnalysisState
  expectedAnalysisTime?: number
  onCancel: () => void
}

const LoadingProgressSnackbar: React.FC<IProps> = ({
  analyisState,
  expectedAnalysisTime,
  onCancel
}: IProps) => {
  const theme = useTheme()

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'bottom',
    horizontal: 'center'
  })

  const { vertical, horizontal, open } = state
  const [canCancel, setCanCancel] = React.useState(false)
  const [cancelTimer, setCancelTimer] = React.useState(0)
  const [successTimer, setSuccessTimer] = React.useState(0)

  useEffect(() => {
    if (analyisState === AnalysisState.analysis) {
      setCancelTimer(0)
      setCanCancel(false)
      const timer = setInterval(() => {
        setCancelTimer(prevProgress =>
          prevProgress >= 100 ? 100 : prevProgress + 1
        )
      }, 60)
      return () => {
        clearInterval(timer)
      }
    }
  }, [analyisState])

  useEffect(() => {
    if (analyisState === AnalysisState.success) {
      setSuccessTimer(0)
      const timer = setInterval(() => {
        setSuccessTimer(prevProgress =>
          prevProgress >= 100 ? 100 : prevProgress + 1
        )
      }, 15)
      return () => {
        clearInterval(timer)
      }
    }
  }, [analyisState])

  useMemo(() => {
    if (analyisState !== AnalysisState.none) {
      setState({ open: true, vertical: 'bottom', horizontal: 'left' })
    }
  }, [analyisState])

  useEffect(() => {
    if (cancelTimer >= 100) {
      setCanCancel(true)
    }
  }, [cancelTimer])

  useEffect(() => {
    if (successTimer >= 100) {
      handleClose()
    }
  }, [successTimer])

  const handleClick = (newState: SnackbarOrigin) => () => {
    setState({ ...newState, open: true })
  }

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const action = (
    <React.Fragment>
      <Button
        name="cancel"
        disabled={!canCancel}
        color="primary"
        size="small"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </React.Fragment>
  )

  const actionSpinner = <React.Fragment></React.Fragment>

  const stopAction = (
    <React.Fragment>
      <ArcIconButton
        name="x"
        style={{ paddingRight: '5px' }}
        onClick={handleClose}
      />
    </React.Fragment>
  )

  const analysisMessage = (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 'bold' }} style={{ paddingRight: '5px' }}>
          1/2
        </Typography>
        <Typography style={{ paddingRight: '10px' }}>Analyzing...</Typography>
        {expectedAnalysisTime && expectedAnalysisTime > 0 ? (
          <LinearProgressWithLabel
            time={expectedAnalysisTime}
          ></LinearProgressWithLabel>
        ) : (
          <ArcSpinner></ArcSpinner>
        )}
      </div>
    </React.Fragment>
  )

  const importingGeometryMessage = (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontWeight: 'bold' }} style={{ paddingRight: '5px' }}>
          2/2
        </Typography>
        <Typography>Importing geometry...</Typography>
      </div>
    </React.Fragment>
  )

  const successMessage = (
    <React.Fragment>
      <Typography>Calculation and model update complete!</Typography>
    </React.Fragment>
  )

  const errorMessage = (
    <React.Fragment>
      <Typography>
        Something went wrong, see the error panel for more details...{' '}
      </Typography>
    </React.Fragment>
  )

  const abortMessage = (
    <React.Fragment>
      <Typography>The analysis was canceled. </Typography>
    </React.Fragment>
  )

  const messages = {
    analysis: analysisMessage,
    importing: importingGeometryMessage,
    success: successMessage,
    error: errorMessage,
    abort: abortMessage
  }

  const actions = {
    analysis: action,
    importing: actionSpinner,
    success: stopAction,
    error: actionSpinner,
    abort: actionSpinner
  }

  return (
    <Snackbar
      // TransitionComponent={TransitionBottom}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      message={messages[analyisState]}
      key={vertical + horizontal}
      action={actions[analyisState]}
      sx={{
        position: 'absolute',
        zIndex: 100,
        bottom: '1rem!important',
        left: '1rem!important',
        '& .MuiSnackbarContent-root': {
          borderRadius: '10px',
          border:
            analyisState === AnalysisState.success
              ? `2px solid ${theme.palette.success.main}`
              : analyisState === AnalysisState.error
              ? `2px solid ${theme.palette.error.main}`
              : analyisState === AnalysisState.abort
              ? `2px solid ${theme.palette.warning.main}`
              : `1px solid rgb(190, 190, 190)`,
          boxShadow: 'none',
          transition: 'all 0.4s'
        }
      }}
    />
  )
}

export default LoadingProgressSnackbar
