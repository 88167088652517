import { useState, createContext } from 'react'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'

import type { ChildrenType } from '~utils/utilityTypes'
import Color from 'color'
import { fontWeight } from '@mui/system'

export const ReactThemeContext = createContext({
  darkMode: false,
  toggleDarkMode: () => {
    return
  }
})

//Arup Colors
export const ArupColorsHex = [
  '#E61E28',
  '#7D4196',
  '#005AAA',
  '#32A4A0',
  '#C83C96',
  '#4BA046',
  '#1E9BD7',
  '#91967D',
  '#E66E23',
  '#50697D'
]

export const ArupColors = ArupColorsHex.map(o => new Color(o, 'hex'))

export const InformThemeProvider = ({ children }: ChildrenType) => {
  const [darkMode, setDarkMode] = useState<boolean>(false)
  const toggleDarkMode = () => setDarkMode(darkMode => !darkMode)

  const theme = createTheme({
    typography: {
      // fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
      fontFamily: 'var(--arc-font-body)',
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 700,
      fontSize: 13,
      caption: {
        fontSize: 11,
        fontWeight: 400
      },
      h1: {
        fontSize: 64,
        fontWeight: 400
      },
      h2: {
        fontSize: 44,
        fontWeight: 400
      },
      h3: {
        fontSize: 32,
        fontWeight: 400
      },
      h4: {
        fontSize: 18
      },
      h5: {
        fontSize: 16,
        fontWeight: 500
      },
      h6: {
        fontSize: 12
      },
      body1: {
        fontSize: 13
      },
      button: {
        fontSize: 12,
        textTransform: 'capitalize'
      },
      subtitle1: {
        fontSize: 12,
        lineHeight: 1.2
      },
      subtitle2: {
        fontSize: 12,
        fontWeight: 500
      }
    },
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: 'rgb(var(--arc-red-060))',
        light: 'rgb(var(--arc-red-060))',
        dark: 'rgb(var(--arc-font-color))',
        contrastText: '#ffffff'
      },
      secondary: {
        main: 'rgb(var(--arc-grey-100))',
        light: 'rgb(var(--arc-grey-100))',
        dark: 'rgb(var(--arc-white-000))',
        contrastText: '#ffffff'
      },
      error: {
        main: 'rgb(var(--arc-red-050))',
        light: 'rgb(var(--arc-red-050))',
        dark: 'rgb(var(--arc-red-030))',
        contrastText: '#ffffff'
      },
      warning: {
        main: 'rgb(var(--arc-orange-050))',
        light: 'rgb(var(--arc-orange-050))',
        dark: 'rgb(var(--arc-orange-030))',
        contrastText: '#ffffff'
      },
      info: {
        main: 'rgb(var(--arc-blue-050))',
        light: 'rgb(var(--arc-blue-050))',
        dark: 'rgb(var(--arc-blue-030))',
        contrastText: '#ffffff'
      },
      success: {
        main: 'rgb(var(--arc-green-050))',
        light: 'rgb(var(--arc-green-050))',
        dark: 'rgb(var(--arc-green-030))',
        contrastText: '#ffffff'
      },
      text: {
        primary: '#323130',
        secondary: 'rgb(var(--arc-grey-060))'
      },
      background: {
        default: !darkMode
          ? 'rgb(var(--arc-grey-010))'
          : 'rgb(var(--arc-grey-090))',
        paper: !darkMode
          ? 'rgb(var(--arc-white-000))'
          : 'rgb(var(--arc-grey-100))'
      }
    }
  })
  return (
    <ReactThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ReactThemeContext.Provider>
  )
}
