import { useEffect, useState } from 'react'

import type { ChartData } from 'chart.js'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import type { DonutChartProps } from './DoughnutChart.types'

ChartJS.register(ArcElement, Tooltip, Legend)

export const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true as const,
      position: 'right' as const,
      labels: {
        boxWidth: 15,
        boxHeight: 8,
        font: {
          size: 10.5
        }
      }
    }
  }
}

export interface IProps {
  data: DonutChartProps
}

const DoughnutChart: React.FC<IProps> = (props: IProps) => {
  const { data, headers, colors } = props.data
  const [chartData, setChartData] = useState<ChartData<'doughnut'>>()

  useEffect(() => {
    if (data) {
      const _colors = colors
      const _chartData = {
        labels: headers,
        datasets: [
          {
            data: data,
            backgroundColor: _colors.map(o => o.alpha(0.8).string()),
            borderColor: 'white',
            borderWidth: 1
          }
        ]
      }
      setChartData(_chartData)
    }
  }, [data, colors, headers])

  return (
    <>
      {chartData ? (
        <div>
          <Doughnut data={chartData} options={options} />{' '}
        </div>
      ) : null}
    </>
  )
}
export default DoughnutChart
