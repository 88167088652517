import React from 'react'
import type { InputForms } from './types'
import { InputFormContext } from './types'

export const useInputFormContext = () => {
  const context = React.useContext<InputForms>(
    InputFormContext as unknown as React.Context<InputForms>
  )

  if (!context) {
    throw new Error('useFormContext must be used within a StoreProvider.')
  }

  return context
}
