import React from 'react'
// import React, { useRef } from "react";
import '@arc-web/components/dist/components/button/arc-button'
import { TextField } from '@mui/material'
import { InputItem } from './styles/styles'
import type { IInputItem } from './types/types'

const Panel: React.FC<IInputItem> = (props: IInputItem) => {
  const component = props.component
  const [value, setValue] = React.useState(props.value)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const keyPress = e => {
    //Enter
    if (e.keyCode == 13 || e.keyCode == 9) {
      props.onChange([e.target.value])
    }
  }

  return (
    <InputItem>
      <TextField
        onChange={handleChange}
        onBlur={e => props.onChange([e.target.value])}
        onKeyDown={keyPress}
        label={component.name}
        size="small"
        value={value}
        disabled={props.disabled}
        name={props.name}
      ></TextField>
    </InputItem>
  )
}

export default Panel
