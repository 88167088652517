import '@arc-web/components/dist/components/button/arc-button'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { memo, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import UploadModal from '~components/modal/UploadStatic/UploadStatic'

import { unityContext } from '~pages/dashboard/components/UnityMount'

const ViewerSettingsMenu = () => {
  const [openGeometryModal, setOpenGeometryModal] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff')

  const onClick = () => {
    setOpenGeometryModal(true)
  }
  const handleColorChange = unityBGColor => {
    unityContext.send('Main', 'SetBackgroundColor', unityBGColor)
    setBackgroundColor(unityBGColor)
  }

  return (
    <UnitySettingsContainer>
      <Typography variant="subtitle1">Upload Static Geometry</Typography>
      <Typography variant="caption">
        Upload a rhino file with static meshes.
      </Typography>
      <StaticGeometry>
        <arc-button type="filled" color="secondary" onClick={onClick}>
          Upload
        </arc-button>
        <UploadModal
          open={openGeometryModal}
          close={!openGeometryModal}
        ></UploadModal>
      </StaticGeometry>
      <Typography variant="subtitle1">CANVAS COLOR</Typography>
      <Typography variant="caption">
        Change the background color of the viewer
      </Typography>
      <ColorPickerContainer>
        <OptionContainer>
          <HexColorPicker
            color={backgroundColor}
            onChange={handleColorChange}
          />
        </OptionContainer>
      </ColorPickerContainer>
    </UnitySettingsContainer>
  )
}
export default memo(ViewerSettingsMenu)

const StaticGeometry = styled('div')({
  display: 'flex'
})

const UnitySettingsContainer = styled('div')({
  width: '20%',
  position: 'fixed',
  right: 'calc(max(18vw, 250px) + 1%)',
  bottom: '60.0px',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0.5rem',
  boxShadow: 'var(--arc-input-box-shadow)',
  backgroundColor: 'rgb(var(--arc-container-color))',
  border: 'solid 1px #BEBEBE',
  padding: '1rem',
  marginTop: '1rem',
  marginRight: '0.5rem',

  '&.collapsed': {
    right: '1%'
  }
})

const OptionContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const ColorPickerContainer = styled('div')({
  marginTop: '1rem'
})
