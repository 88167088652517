import '@arc-web/components/dist/components/button/arc-button'
import '@arc-web/components/dist/components/icon-button/arc-icon-button'
import '@arc-web/components/dist/components/icon/arc-icon'
import '@arc-web/components/dist/components/menu-item/arc-menu-item'
import '@arc-web/components/dist/components/menu/arc-menu'
import { useMsal } from '@azure/msal-react'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useRef } from 'react'

import { getUserRole, UserRoles } from 'src/lib/auth/auth'
import { useProjectsByUser } from '~api/inform/useProjectsByUser'
import { ProjectGrid } from '~components/grid/ProjectGrid'
import { ProjectSidebar } from '~pages/projects/ProjectSideBar'

const Projects = () => {
  const { accounts } = useMsal()

  const myProjects = useRef(null)
  const featuredProjects = useRef(null)
  const allProjects = useRef(null)
  const userRole = getUserRole(accounts[0])
  const userProjects = useProjectsByUser()
  return (
    <arc-container
      // TODO : fix it correctly
      theme="light"
      style={{
        position: 'fixed',
        top: '3rem',
        bottom: 0,
        width: '100vw',
        flexDirection: 'column'
      }}
    >
      {userRole === UserRoles.guest ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column'
          }}
        >
          <Typography variant="h3">
            {' '}
            You don't have access to this page.{' '}
          </Typography>
          <Typography variant="body1">
            You do have access to specific projects. Go to this link directly to
            view your project.
          </Typography>
        </div>
      ) : (
        <>
          <arc-sidebar slot="side" style={{ '--sidebar-width': '18%' }}>
            <ProjectSidebar
              projectCount={0}
              myProjects={myProjects}
              featuredProjects={featuredProjects}
              allProjects={allProjects}
            ></ProjectSidebar>
          </arc-sidebar>
          <SelectProjectsContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div>
                <Typography variant="subtitle1" color="primary">
                  Projects
                </Typography>
                <Typography
                  variant="h4"
                  style={{ fontWeight: '300', lineHeight: '1' }}
                >
                  Arup InForm
                </Typography>
                <Typography variant="subtitle1">
                  The parametric engine for Total Design.
                </Typography>
              </div>
            </div>

            <div className="projectTypes" style={{ marginTop: '20px' }}>
              <div
                ref={myProjects}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography variant="h5" style={{ marginRight: '5px' }}>
                  My Projects
                </Typography>
              </div>
              <div className="projectCardsContainer">
                <div
                  style={{
                    width: '100%',
                    marginLeft: '-20px',
                    minHeight: '200px'
                  }}
                >
                  {userProjects.projects && !userProjects.isLoading ? (
                    <ProjectGrid
                      addProject={true}
                      items={userProjects.projects}
                      showDots={true}
                      cardType="projectCard"
                    ></ProjectGrid>
                  ) : (
                    <arc-spinner style={{ fontSize: '2rem' }}></arc-spinner>
                  )}
                </div>
              </div>
            </div>
          </SelectProjectsContainer>
        </>
      )}
    </arc-container>
  )
}

export default Projects

export const SelectProjectsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '3rem',
  height: '100%',
  overflow: 'scroll',

  '& .projectTypes': {
    width: '100%'
  },

  '&  h1': {
    marginLeft: '1rem',
    marginTop: '0.5rem',
    marginBottom: 0,
    fontWeight: 'normal'
  },

  '& .projectCardsContainer': {
    marginTop: '1rem',
    marginBottom: '2rem'
  },

  '& .projectCardsContainer a': {
    textDecoration: 'none'
  },

  '& .projectCardsContainer #newProjectCard': {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1.25rem'
  },
  '& .projectCardsContainer #newProjectCard img': {
    boxShadow: 'var(--arc-input-box-shadow)',
    borderRadius: '50%',
    padding: '0.5rem'
  },

  '& .projectCardsContainer #newProjectCard h2': {
    fontWeight: 'normal',
    fontSize: '1.5rem',
    marginTop: '2.5rem'
  },

  '& .projectCardsContainer .showMoreContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  '& .projectCardsContainer .showMoreContainer .showMore': {
    boxShadow: 'var(--arc-input-box-shadow)',
    borderRadius: '10px'
  },

  '& .projectCardsContainer .MuiPaper-root': {
    margin: '1rem',
    marginTop: 0,
    width: '250px',
    minWidth: '250px'
  },

  '& .projectCardsContainer .MuiPaper-root:hover': {
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    transition: '0.3s'
  }
})
