import '@arc-web/components/dist/components/button/arc-button'
import RhinoUpload from '../../form/RhinoUpload'
import { useLocation } from 'react-router-dom'
import BaseModal from '../BaseModal'

type UploadModalProps = {
  open: boolean
  close: boolean
}

const UploadModal = (props: UploadModalProps) => {
  const location = useLocation()
  return (
    <BaseModal
      open={props.open}
      close={props.close}
      title="Upload Static Geometry"
    >
      <RhinoUpload projectId={location.pathname.split('/')[2]}></RhinoUpload>
    </BaseModal>
  )
}

export default UploadModal
