import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useToken } from '~api/useToken'
import {
  initializeInputData,
  initializeInputNames
} from '~pages/inputs/utils/dashboardInputFunctions'
import { getComponents } from './api'

//Get the UI Components defined by the user in Grasshopper.
export const useComponents = (projectId?: string) => {
  const [_projectId] = useState<string | undefined>(projectId)
  const { getToken } = useToken()
  const { data, isLoading, error } = useQuery({
    queryKey: ['components', projectId],
    queryFn: async () => getComponents(projectId ?? '', await getToken()),
    enabled: !!_projectId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  })
  const inputs = data?.inputs
  const names = initializeInputNames(inputs)
  const values = initializeInputData(inputs) as Record<
    string,
    string[] | number[]
  >
  return {
    defaultInputValues: values,
    defaultInputNames: names,
    inputComponents: inputs,
    outputComponents: data?.outputs,
    isLoading,
    error
  }
}
