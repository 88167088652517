import type { Theme } from '@mui/material'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import type { LinearProgressProps } from '@mui/material/LinearProgress'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import * as React from 'react'

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; theme: Theme }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            backgroundColor: props.theme.palette.background.default,
            height: '5px',
            borderRadius: '3px'
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

interface IProps {
  time: number
}

export default function LinearWithValueLabel({ time }: IProps) {
  const [progress, setProgress] = React.useState(1)
  const theme = useTheme()

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress =>
        prevProgress >= 100 ? 100 : prevProgress + 1
      )
    }, time / 100)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <Box sx={{ width: '200px' }}>
      <LinearProgressWithLabel value={progress} theme={theme} />
    </Box>
  )
}
