import { styled } from '@mui/material'

export const InputItem = styled('div')({
  // marginBottom: '15px'
})

export const InputItemUpdate = styled('div')({
  maxHeight: '400px',
  height: '100%',
  width: '100%',
  // padding: "1rem",
  // boxShadow: "var(--arc-input-box-shadow)",
  marginBottom: '8px',

  '& h1': {
    fontSize: '0.8rem',
    fontWeight: 'normal',
    color: 'gray',
    margin: 0
  },

  '& h2': {
    fontSize: '0.75rem',
    fontWeight: '500',
    marginLeft: 'auto',

    width: '50%',
    display: 'inline-block'
  }
})

export const EditContainerStyles = styled('div')({
  border: '1px solid',
  borderColor: 'var(--color)',
  backgroundColor: 'rgb(var(--arc-grey-000))',
  borderRadius: '8px',
  padding: '10px 10px 0 10px',
  marginBottom: '10px',
  transition: '0.5s'
})
