import axios from 'axios'
import type { OutputAndUri } from '~pages/outputs/types/OutputItem'
import type {
  InitialInput,
  Output,
  ProjectInfo,
  RhinoComputeResponse,
  RhinoComputeType
} from './types'
import { InFormError } from './types'

export const fetchDataFromUrl = async (url: string) => {
  return await axios.get(url)
}

export const checkCSVValid = async (
  file: File,
  projectInfo: string,
  accessToken: string
) => {
  const body = new FormData()
  body.append('file', file)
  body.append('projectInfo', projectInfo)

  const response = await fetch(
    'https://inform-csharp.azurewebsites.net/api/PostParameterspaceProject?code=9yY8m2Jyu0h_EX2vy5JA9jNymdC8t_GQ_QyGdwoXvcWCAzFuNLzMSA==',
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      body
    }
  )

  if (!response.ok) {
    throw new Error(await response.text())
  }

  return {
    status: '200',
    message: await response.text()
  }
}

export const checkGhValid = async (file: File, accessToken: string) => {
  const formdata = new FormData()
  formdata.append('file', file)

  const response = await fetch(
    'https://inform-external.azurewebsites.net/api/ValidateGrasshopperFile?code=ziL3Nh3oUvR2CL3ZzK16DxZ_RkPm2tkhbnC9uWz_i9zOAzFuPLY5Ow==',
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      body: formdata
    }
  )

  if (!response.ok) {
    return {
      status: '400',
      message: await response.text()
    }
  }

  return {
    status: '200',
    message: await response.json()
  }
}

export const informExtractGHModule = async (arg: any) => {
  const response = await axios.post(
    'https://inform-openapi.azurewebsites.net/api/ExtractGrasshopperOpen?',
    arg
  )
  return response
}

export const getComponents = async (
  projectId: string,
  token: string
): Promise<InitialInput> => {
  const response = await fetch(
    `https://inform-external.azurewebsites.net/api/components/${projectId}?code=09FZoqsKZFxf2UDiClCSDEKUTD2tNyv4MzWKCgBZHvEfAzFuBRHnkQ==`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  return response.json()
}

export const submitUploadForm = async (body: any, headers: any) => {
  const response = await axios.post(
    `https://inform-external.azurewebsites.net/api/project?code=7Pmnrf7uN28RTsh0ol8hTwsE0rFsM5KOzRarx5NLbzliAzFuecXGiQ==`,
    body,
    { headers: headers }
  )
  return response
}

export async function uploadRhinoStatic(file, token, projectId) {
  const formdata = new FormData()
  formdata.append('file', file)
  const url = `https://inform-external.azurewebsites.net/api/CreateStaticGeometry?code=AgN2iypgXSBVDQcK8gym4x98VH0bhbJEFhGuUiTCE4e9AzFuAurn9A==`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      projectId: projectId
    },
    body: formdata
  })
  return response.text()
}

export const getStaticGeometry = async (
  projectId: string,
  token: string
): Promise<{ outputs: Record<string, Output> } | null> => {
  const response = await fetch(
    `https://inform-external.azurewebsites.net/api/staticgeometry/${projectId}?code=CDHbZSFpqlhGK_E1ueD0fgPtBSja3SoV7fu5zHRMm2Z3AzFuF-HyiA==`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        projectId: projectId
      }
    }
  )
  if (!response.ok) {
    return null
  }

  return response.json()
}

export async function getBlobSignedUrl(blobName: string, token: string) {
  const body = {
    container: 'grasshopper-test',
    blobName: blobName,
    permissions: 'r'
  }

  const response = await axios.post(
    'https://informsas.azurewebsites.net/api/SAStoken?',
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data.uri
}

export const informRhinoComputeCall = async (
  body: RhinoComputeType,
  headers
) => {
  const response = await axios.post(
    'https://inform-external.azurewebsites.net/api/ComputeModel/{projectId}?code=Q6gCm6CLcURSqMdraTBwoKEjN7muGowCtsZzALo3lhSqAzFu0Jid-w==',

    body,
    { headers: headers }
  )
  return response
}

interface PromiseWithCancel<T> extends Promise<T> {
  cancel: () => void
}

export let computeAbortController = new AbortController()

export const postRhinoComputeCalculation = async (
  body: RhinoComputeType,
  token: string
): Promise<RhinoComputeResponse> => {
  computeAbortController = new AbortController()
  try {
    const response = await fetch(
      `https://inform-external.azurewebsites.net/api/ComputeModel/${body.projectId}?code=Q6gCm6CLcURSqMdraTBwoKEjN7muGowCtsZzALo3lhSqAzFu0Jid-w==`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        signal: computeAbortController.signal,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    if (!response || response.status >= 500) {
      return Promise.reject(
        new InFormError(
          ['An unexpected error occurred on the inForm backend.'],
          [],
          1
        )
      )
    }

    if (!response.ok) {
      const res = (await response.json()) as unknown as RhinoComputeResponse
      return Promise.reject(new InFormError(res.errors, res.warnings, 1))
    }

    return response.json()
  } catch (ex: unknown) {
    if (isAbortError(ex)) {
      return Promise.reject(
        new InFormError([], ['The analysis was canceled by the user.'], 2)
      )
    }
    return Promise.reject(
      new InFormError(
        ['An unexpected error occurred on the inForm backend.'],
        [],
        1
      )
    )
  }
}

export const getOutputsCountByProject = async (
  projectId: string,
  token: string
) => {
  const response = await axios.get(
    'https://inform-csharp.azurewebsites.net/api/CountProjectOutputs?code=1KF6S_8J-2WEv7UxKws5RFSEIbee6EU8Zd2aomGOW9VtAzFuAv9q4w==',
    {
      params: { projectId: projectId },
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response
}

export const postBatchComputeRequest = async (body: any, token) => {
  const response = await axios.post(
    'https://inform-csharp.azurewebsites.net/api/KafkaCompute?code=n3-dy9P6sgD2A5uznZQw4ALwc_4KVyypqJb0BwVT9GIKAzFuCopTaQ==',
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response
}

export async function uploadGh(file, token) {
  const formdata = new FormData()
  formdata.append('file', file)
  const url =
    'https://inform-external.azurewebsites.net/api/model?code=G-xSgfUB3ZXYOx94iypqhXkXWlfB0t6GlqsU6I0qNvU_AzFu2RRZyA=='
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formdata
  })
  return response.json()
}

export async function downloadGh(filePath, token) {
  const url = 'https://informsas.azurewebsites.net/api/SAStoken?'

  const mongoGetBlob = {
    container: 'grasshopper-test',
    blobName: filePath,
    permissions: 'r'
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(mongoGetBlob)
  })

  return response.json()
}

// axios.post(
//   `https://extractgrasshopper.azurewebsites.net/api/RhinoComputeCall?code=epO80rYwadpx6inhssezW/3VocslKuVgJoqIMdMagnKY1Dilm1OamA==`,
//   data,
// );

export const getProfilePhoto = accessToken =>
  axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const getProjectByProjectId = async (
  projectId: string,
  token: string
): Promise<ProjectInfo> => {
  const response = await fetch(
    `https://inform-external.azurewebsites.net/api/project/${projectId}?code=IdPPLy3rqTNQyQQvUuCNyWSufgQJ8KwHKZ6NH0EAQHxdAzFuTVs0DA==`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  return response.json()
}

export const getProjectByUser = async (
  token: string
): Promise<ProjectInfo[]> => {
  const response = await fetch(
    `https://inform-external.azurewebsites.net/api/projects?code=Uv8scZs_-IUQ13UlNKsH2gPv6UcCA-2zV1FNWziyv5PSAzFugqFRTw==`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  return response.json()
}

export const fetchUrl = async (url: string) => {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  return response.json()
}

function isAbortError(error: any): error is DOMException {
  if (error && error.name === 'AbortError') {
    return true
  }
  return false
}
