import '@arc-web/components/dist/components/button/arc-button'
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import React, { useState } from 'react'
import EditContainer from './edit/EditContainer'
import { InputItem } from './styles/styles'
import type { IInputItem } from './types/types'

const CheckList: React.FC<IInputItem> = (props: IInputItem) => {
  const component = props.component
  const [values, setValues] = React.useState(
    component.options[0].listData.map(option => option.value)
  )
  const [names, setNames] = React.useState(
    component.options[0].listData.map(option => option.name)
  )
  const allNames = component.options[0].listData.map(x => x.name)

  const onCheck = (name, value) => {
    if (!name || !value) {
      return
    }
    let oVals: any
    let oNames: any

    //Delete or add to list
    if (names && names.includes(name)) {
      const vals = [...values] as any
      const valueIndex = names.indexOf(name) as number
      vals.splice(valueIndex[0], 1)
      oVals = vals
      oNames = names.filter(x => x !== name)
    } else {
      oVals = [...values, value]
      oNames = [...names, name]
    }

    //Keep original order of list (otherwise the Redis Cache will trip)
    const oValsSorted = [] as any
    const oNamesSorted = [] as any
    for (let i = 0; i < allNames.length; i++) {
      const name = allNames[i]
      for (let j = 0; j < oNames.length; j++) {
        const oName = oNames[j]
        const oValue = oVals[j]
        if (name === oName) {
          oNamesSorted.push(name)
          oValsSorted.push(oValue)
        }
      }
    }

    setNames(oNamesSorted)
    setValues(oValsSorted)

    //Set the type of the values.
    const typedList = [] as any
    if (component.typeHint === 'Number' || component.typeHint === 'Integer') {
      for (let i = 0; i < oValsSorted.length; i++) {
        const element = oValsSorted[i]
        typedList.push(Number(element))
      }
    } else {
      for (let i = 0; i < oValsSorted.length; i++) {
        const element = oValsSorted[i]
        typedList.push(element)
      }
    }
    props.onChange(typedList)
  }

  const [disabled, setDisabled] = useState(true)

  if (props.editable) {
    return (
      <EditContainer
        component={component}
        editing={e => {
          setDisabled(!disabled)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id={component.param}>{component.name}</InputLabel>
          <Select
            labelId={component.description}
            id={component.param}
            multiple
            name={names}
            value={values}
            disabled={disabled}
            size="small"
            renderValue={selected => (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected.map((value, index) => (
                  <Chip
                    style={{
                      height: '50%',
                      fontSize: '0.6rem',
                      marginBottom: '3px'
                    }}
                    key={names[index] + value}
                    label={names[index]}
                  />
                ))}
              </div>
            )}
            label={component.name}
          >
            {component.options[0].listData.map(option => (
              <MenuItem
                key={option.name + option.value}
                value={option.value}
                style={{
                  backgroundColor: 'rgb(var(--arc-container-color))'
                }}
                onClick={e => {
                  onCheck(option.name, option.value)
                }}
              >
                <Checkbox checked={names.includes(option.name)} />
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </EditContainer>
    )
  }

  if (props.hidden) {
    return <></>
  }

  return (
    <InputItem style={{ display: 'flex' }}>
      <FormControl fullWidth>
        <InputLabel id={component.param}>{component.name}</InputLabel>
        <Select
          labelId={component.description}
          id={component.param}
          multiple
          name={names}
          value={values}
          size="small"
          renderValue={selected => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selected.map((value, index) => (
                <Chip
                  style={{
                    height: '50%',
                    fontSize: '0.6rem',
                    marginBottom: '3px'
                  }}
                  key={names[index] + value}
                  label={names[index]}
                />
              ))}
            </div>
          )}
          label={component.name}
          // onChange={handleChange}
          disabled={props.disabled}
        >
          {component.options[0].listData.map(option => (
            <MenuItem
              key={option.name + option.value}
              value={option.value}
              style={{
                backgroundColor: 'rgb(var(--arc-container-color))'
              }}
              onClick={e => {
                onCheck(option.name, option.value)
              }}
            >
              <Checkbox checked={names && names.includes(option.name)} />
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </InputItem>
  )
}

export default CheckList
