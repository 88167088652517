import type { CSSProperties } from 'react'
import { useState } from 'react'
import '@arc-web/components/dist/components/button/arc-button'
import { FormLabel, Chip } from '@mui/material'
import { EditContainerStyles, InputItemUpdate } from '../styles/styles'
import type { ILabel } from './EditLabels'
import EditLabels from './EditLabels'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import type { IItem } from '../../../../declarations'
import type { ChildrenType } from '~utils/utilityTypes'

type EditContainerProps = {
  component: any
  editing: (event: boolean) => void
  value?: any
  borderColor?: string
  onSave?: (event: IItem) => void
} & ChildrenType

const EditContainer = (props: EditContainerProps) => {
  const component = props.component
  const [editingMode, setEditingMode] = useState(false)
  const [visible, setVisible] = useState(true)
  const [label, setLabel] = useState<ILabel>({
    title: component.name,
    description: component.description
  })

  const onSubmitEdit = () => {
    const newComponent = structuredClone(component)
    newComponent['description'] = label.description
    newComponent['name'] = label.title
    newComponent['hidden'] = !visible
    newComponent['default'][0] = { value: props.value }

    props.onSave && props.onSave(newComponent)
  }

  const onClickEdit = () => {
    setEditingMode(!editingMode)
    props.editing(!editingMode)
    if (editingMode) {
      onSubmitEdit()
    }
  }

  const onCancelEdit = () => {
    setEditingMode(!editingMode)
    props.editing(!editingMode)
  }

  const standardColor = {
    '--color': editingMode ? props.borderColor : 'rgb(var(--arc-grey-020))'
  } as CSSProperties

  const hiddenColor = {
    '--color': 'rgb(var(--arc-purple-050))'
  } as CSSProperties

  return (
    <EditContainerStyles style={visible ? standardColor : hiddenColor}>
      {!visible && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row-reverse'
          }}
        >
          <Chip
            title="hidden"
            label="hidden"
            style={{ height: '15px', fontSize: '0.5rem' }}
            sx={{
              backgroundColor: 'rgb(var(--arc-purple-040))',
              color: 'white'
            }}
          ></Chip>
        </div>
      )}

      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <InputItemUpdate>
          {editingMode && (
            <EditLabels
              component={component}
              visibleDefault={visible}
              labelDefault={label}
              label={e => {
                setLabel(e)
              }}
              isVisible={e => {
                setVisible(e)
              }}
              onChangeLabel
            ></EditLabels>
          )}
          <FormLabel component="legend" style={{ marginBottom: '5px' }}>
            {component.name}
          </FormLabel>
          <div>{props.children}</div>
        </InputItemUpdate>
        {!editingMode && (
          <EditOutlinedIcon
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            sx={{ color: 'rgb(var(--arc-grey-060))', height: '1rem' }}
            onClick={onClickEdit}
          ></EditOutlinedIcon>
        )}
      </div>
      {editingMode && (
        <div
          style={{
            margin: '5px 0 10px 0',
            display: 'flex',
            flexDirection: 'row-reverse',
            height: '20px'
          }}
        >
          <arc-button
            size="small"
            type="filled"
            color="secondary"
            style={{ fontSize: '0.6rem', width: '50px', height: '20px' }}
            onClick={onClickEdit}
          >
            Save
          </arc-button>
          <arc-button
            size="small"
            type="outlined"
            style={{ fontSize: '0.6rem', width: '50px', marginRight: '5px' }}
            onClick={onCancelEdit}
          >
            Cancel
          </arc-button>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          cursor: 'grab',
          color: 'rgb(var(--arc-grey-060))'
        }}
      >
        <DragHandleIcon></DragHandleIcon>
      </div>
    </EditContainerStyles>
  )
}

export default EditContainer
