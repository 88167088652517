import localConfig from './local'
import prodConfig from './prod'
import devConfig from './dev'
import testConfig from './test'
import externalConfig from './external'

export interface IEnvConfig {
  activeDirectory: {
    appId: string
    redirectUri: string
  }
}

let config: IEnvConfig

const hostname = window.location.hostname

if (hostname === 'localhost') {
  config = localConfig
} else if (hostname.includes('dev')) {
  config = devConfig
} else if (hostname.includes('test')) {
  config = testConfig
} else if (hostname.includes('cern') || hostname.includes('witty-bay')) {
  config = externalConfig
} else {
  config = prodConfig
}

export default config
