import type { AppThunk } from '../interfaces'
import { TOGGLE_ANALYSIS_NAME } from './actions'

export const outputObjectActions = {
  toggleAnalysisView:
    (name: string | null): AppThunk =>
    dispatch => {
      dispatch({
        type: TOGGLE_ANALYSIS_NAME,
        name: name
      })
    }
}
