import { useMsal } from '@azure/msal-react'
import { InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'

type IProps = {
  onChangeInput: (event: any) => void
  values: object | undefined
}

type PropsType = IProps

type FormState = {
  confidential: boolean
  featured: boolean
}

const AddNewForm: React.FC<PropsType> = ({ onChangeInput, values }) => {
  const { accounts } = useMsal()
  const accountInfo = accounts[0] || {}

  const [state, setState] = useState<FormState>({
    confidential: false,
    featured: false
  })

  const v = values

  const handleChange = event => {
    let value = event.target.value
    if (event.target.name === 'featured' && value === 'True') {
      value = true
    } else if (event.target.name === 'featured' && value === 'False') {
      value = false
    }

    setState({
      ...state,
      [event.target.name.toLowerCase()]: value
    })
  }

  useEffect(() => {
    if (state) {
      onChangeInput(state)
    }
  }, [state, onChangeInput])

  if (!accountInfo) {
    return null
  }

  const onFormSubmit = event => {
    event.preventDefault()
    const formElements = event.target.elements
  }

  const inputObj = {
    General: {
      title: {
        name: 'Title',
        type: 'textfield',
        label: 'title',
        enabled: true,
        require: true,
        default: v ? v['title'] : null
      },
      // collection: {name:"Collection", type:"textfield", enabled: false},
      createdBy: {
        name: 'Created by',
        type: 'textfield',
        label: 'createdBy',
        enabled: true,
        require: false,
        default: v ? accountInfo?.username : null
      },
      // contributors: {name:"Contributors", type:"textfield", enabled: false},
      // region: {name:"Region", type:"textfield", enabled: true},
      confidential: {
        name: 'Confidential',
        type: 'dropdown',
        enabled: true,
        label: 'confidential',
        default: { value: false, label: false },
        choices: [
          { value: true, label: true },
          { value: false, label: false }
        ]
      },
      featured: {
        name: 'Featured',
        type: 'dropdown',
        enabled: true,
        label: 'featured',
        default: { value: false, label: 'False' },
        choices: [
          { value: false, label: 'False' },
          { value: true, label: 'True' }
        ]
      }
    },
    Project: {
      // location: {name:"Location", type:"textfield", enabled: true},
      // function: {name:"Function", type:"textfield", enabled: false},
      description: {
        name: 'Description',
        label: 'description',
        type: 'textfield',
        enabled: true,
        default: v ? v['description'] : null
      },
      // phase: {name:"Phase", type:"dropdown", enabled: false, choices: [
      //   { value: true, label: 'True'},
      //   { value: false, label: 'False'}]},
      jobNr: {
        name: 'Job number',
        label: 'jobNr',
        type: 'textfield',
        enabled: true,
        default: v ? v['jobNr'] : null
      },
      client: {
        name: 'Client',
        label: 'client',
        type: 'textfield',
        enabled: false,
        default: v ? v['client'] : null
      }
    },
    Version: {
      version: {
        name: 'Version',
        label: 'version',
        type: 'textfield',
        enabled: true,
        default: v ? v['version'] : null
      },
      latest: {
        name: 'Latest',
        type: 'dropdown',
        label: 'latest',
        enabled: false,
        choices: [
          { value: true, label: 'True' },
          { value: false, label: 'False' }
        ]
      },
      lastUpdated: {
        name: 'Last updated',
        label: 'lastUpdated',
        type: 'textfield',
        enabled: false,
        default: v ? v['lastUpdated'] : null
      },
      comment: {
        name: 'Comment',
        label: 'comment',
        type: 'textfield',
        enabled: false,
        default: v ? v['comment'] : null
      }
    }
  }

  const formItem = (item, key) => {
    switch (item.type) {
      case 'textfield':
        return (
          <>
            <TextField
              label={item.name}
              name={item.label}
              defaultValue={item.default}
              variant="outlined"
              fullWidth
              color="error"
              size="medium"
              onChange={handleChange}
              required={item.require}
            />
          </>
        )
      case 'dropdown':
        return (
          <>
            <InputLabel key={key} id={key}>
              {item.name}
            </InputLabel>
            <Select
              labelId={key}
              fullWidth
              // styles={colourStyles}
              // MenuProps={MenuProps}
              defaultValue={item.default.label}
              input={<OutlinedInput label={item.name} />}
              onChange={handleChange}
              name={item.label}
            >
              {item.choices.map(choice => (
                <MenuItem key={choice.label} value={choice.label}>
                  {choice.label}
                </MenuItem>
              ))}
            </Select>
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <Grid container style={{ justifyContent: 'space-evenly' }}>
      <form onSubmit={onFormSubmit} method="get" id="my-form">
        {Object.entries(inputObj).map(([key, val]) => (
          <Grid container key={key} spacing={1}>
            <Grid item xs={12} sm={12} key={key} style={{ marginTop: 10 }}>
              <Typography key={key} component="div" variant="subtitle2">
                {key}
              </Typography>
            </Grid>
            {Object.entries(val).map(([keyInput, valInput]) => (
              <Grid item xs={12} sm={6} key={keyInput}>
                {valInput['enabled'] ? formItem(valInput, keyInput) : null}
              </Grid>
            ))}
          </Grid>
        ))}
      </form>
    </Grid>
  )
}
export default AddNewForm
