import { useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import '@arc-web/components/dist/components/container/arc-container'
import cityOutline from '~assets/lottie/cityOutline.json'
import Lottie from 'react-lottie'
import { Providers } from '@microsoft/mgt-element'
import { Msal2Provider } from '@microsoft/mgt-msal2-provider'
import { loginRequest, msal } from 'src/lib/auth/auth'

const SignIn = () => {
  const submit = async () => {
    Providers.globalProvider = new Msal2Provider({
      publicClientApplication: msal,
      scopes: loginRequest.scopes
    })

    if (Providers.globalProvider.login) {
      await Providers.globalProvider.login()
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: cityOutline,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <Grid
      style={{ display: 'fixed', alignItems: 'center', height: '80%' }}
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        md={12}
        lg={4}
        sx={{
          height: 'auto',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <div style={{ paddingLeft: '15%' }}>
          <Typography variant="h6" color="primary">
            Optioneering
          </Typography>
          <Typography variant="h1">Arup Inform</Typography>
          <Typography variant="h5" style={{ fontWeight: '300' }}>
            Quickly compare and explore numerous design solutions to make
            informed decisions.
          </Typography>

          <arc-button
            onClick={submit}
            type="filled"
            color="secondary"
            style={{ marginTop: 15 }}
          >
            {' '}
            LOGIN{' '}
          </arc-button>
        </div>
      </Grid>
      <Grid style={{ display: 'fixed' }} item xs={12} md={12} lg={8}>
        <Lottie width="100%" height="100%" speed={2} options={defaultOptions} />
      </Grid>
    </Grid>
  )
}

export default SignIn
