import { ArcRadio } from '@arc-web/components/react'
import { useTheme } from '@mui/material'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLayerStateContextUnity,
  setLayerStateUnity
} from '~api/unity/apiUnity'
import { useInputFormContext } from '~pages/dashboard/useInputFormContext'
import type { RootState } from '../../../../store'
import { outputObjectActions } from '../../../../store/outputObjects'
import Legend from '../legend/Legend'
import type { ToggleLayerProps } from './ToggleLayer.types'

export interface IProps {
  data: ToggleLayerProps
}

///With this component
const ToggleAnalysis: React.FC<IProps> = (props: IProps) => {
  const { title, on, off, legendtitle, legendvalues, legendcolors, hidden } =
    props.data
  const dispatch = useDispatch()
  const theme = useTheme()
  const { analysisToggled } = useSelector(
    (state: RootState) => state.outputObjects
  )

  /**
   * The layerstates form in the inputs. When layers are toggled on/off, change state of buttons in the layers tab.
   */
  const { setValue } = useInputFormContext().layersForm

  const [toggled, setToggled] = useState(analysisToggled === title)

  /**
   * TODO: MAKE DIFFERENTIATION BETWEEN CONTEXT AND DYNAMIC GEOMETRY
   */
  const toggleLayer = useCallback(
    (param: string, state: boolean) => {
      setLayerStateUnity({
        param: 'rh_out:' + param.toLowerCase(),
        state: state
      })
      setLayerStateContextUnity({
        param: param.toLowerCase(),
        state: state
      })
      setValue('rh_out:' + param.toLowerCase(), state)
      setValue(param.toLowerCase(), state)
    },
    [dispatch]
  )

  useMemo(() => {
    if (analysisToggled === title) {
      return
    }
    if (!on) {
      return
    }
    for (let i = 0; i < on.length; i++) {
      const param = on[i] as string
      toggleLayer(param, false)
    }
    setToggled(false)
  }, [analysisToggled])

  useEffect(() => {
    if (!on) {
      return
    }
    if (analysisToggled === title || toggled) {
      for (let i = 0; i < on.length; i++) {
        const param = on[i] as string
        toggleLayer(param, true)
      }
      setToggled(true)
    } else {
      for (let i = 0; i < on.length; i++) {
        const param = on[i] as string
        toggleLayer(param, false)
      }
      setToggled(false)
    }
  }, [])

  if (!title || !on || !off) {
    return null
  }

  if (hidden && hidden.includes('True')) {
    return null
  }

  //Toggles on or off the geometry layers defined by the user.
  const handleClick = e => {
    e.preventDefault()

    if (analysisToggled !== title) {
      dispatch(outputObjectActions.toggleAnalysisView(title))
      setToggled(true)
      for (let i = 0; i < on.length; i++) {
        const param = on[i] as string
        toggleLayer(param, true)
      }

      for (let i = 0; i < off.length; i++) {
        const param = off[i] as string
        toggleLayer(param, false)
      }
    } else {
      dispatch(outputObjectActions.toggleAnalysisView(null))
      setToggled(false)
      for (let i = 0; i < on.length; i++) {
        const param = on[i] as string
        toggleLayer(param, false)
      }

      for (let i = 0; i < off.length; i++) {
        const param = off[i] as string
        toggleLayer(param, true)
      }
    }
  }

  return (
    <>
      {toggled ? (
        <>
          <ArcRadio
            color="secondary"
            onClick={handleClick}
            checked
            style={{
              height: '20px',
              display: 'flex',
              color: theme.palette.text.primary
            }}
          >
            {title}
          </ArcRadio>
          {legendtitle ? (
            <Legend
              data={{
                title: legendtitle,
                values: legendvalues,
                colors: legendcolors
              }}
            ></Legend>
          ) : (
            ''
          )}
        </>
      ) : (
        <ArcRadio
          color="secondary"
          onClick={handleClick}
          checked={false}
          style={{
            height: '20px',
            display: 'flex',
            color: theme.palette.text.secondary
          }}
        >
          {title}
        </ArcRadio>
      )}
    </>
  )
}

export default memo(ToggleAnalysis)
