import type { Output } from '~api/inform/types'
import ToggleAnalysis from '../components/button/ToggleLayer'
import BarChart from '../components/chart/BarChart'
import DoughnutChart from '../components/chart/DoughnutChart'
import ProgressBar from '../components/chart/ProgressBar'
import SpotlightNumber from '../components/spotlight/SpotlightNumber'
import DataList from '../components/table/DataList'
import type { Component } from 'src/declarations'
import SelectedObject from '../components/select/Selected'

export const OutputItemsDictionary = {
  'Data List': DataList,
  'Bar Chart': BarChart,
  'Doughnut Chart': DoughnutChart,
  'Progress Bars': ProgressBar,
  'Highlight Data': SpotlightNumber,
  'Toggle Analysis': ToggleAnalysis,
  SelectObject: SelectedObject
}

export const enum OutputItemsEnum {
  'Data List',
  'Bar Chart',
  'Doughnut Chart',
  'Progress Bars',
  'Highlight Data',
  'Toggle Analysis',
  'SelectObject'
}

export interface IOutputItem {
  item: Component
  outputData?: Record<string, Output>
  path?: string | null
  isLoading?: boolean
  selected?: string | null
}

export interface OutputAndUri {
  Output: {
    paramName: string
    rhinoType: string
  }
  Uri: string
}
