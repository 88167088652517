import '@arc-web/components/dist/components/button/arc-button'
import { useMsal } from '@azure/msal-react'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'
import { CircularProgress, Container, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Lottie from 'react-lottie'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { checkGhValid, submitUploadForm, uploadGh } from '~api/inform/api'
import successLottie from '~assets/lottie/success.json'
import { CopyLink } from '~components/link/CopyLink'
import { getAccessToken } from '../../lib/auth/auth'

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderWidth: 3,
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

type IProps = {
  projectJson: object
}

const FileUpload = (props: IProps) => {
  const [success, setSuccess] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [totalSize, setTotalSize] = useState<number>(0.0)
  const [confirmedFiles, setConfirmedFiles] = useState<any>([])
  const [error, setError] = useState<string[]>([''])
  const [projectId, setProjectId] = useState<string>('')
  const [previewData, setPreviewData] = useState<any>()
  const navigate = useNavigate()

  const { accounts, instance } = useMsal()
  const [accessToken, setAccessToken] = useState<string>()

  useEffect(() => {
    getAccessToken(accounts[0], instance).then(token => setAccessToken(token))
  }, [accounts, instance])

  const onClickButton = e => {
    navigate(`/project/${projectId}`)
    window.location.reload()
  }

  const dispatch = useDispatch()

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles: 1,
    maxSize: 50e6,
    onDrop: acceptedFiles => {
      setError([])
      acceptedFiles.map(file => {
        return setTotalSize(
          totalSize => totalSize + parseFloat((file.size / 1e6).toFixed(2))
        )
      })
      setConfirmedFiles([...acceptedFiles])

      const token = accessToken
      if (!token) {
        return
      }
      checkGhValid(acceptedFiles[0], token)
        .then(res => {
          if (res.status !== '200') {
            setError(JSON.parse(res.message) as string[])
            acceptedFiles = []
          } else {
            setPreviewData(res.message)
            setConfirmedFiles([...acceptedFiles])
          }
        })
        .catch(e => {
          //
        })
    }
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const handleDrop = async () => {
    const token = accessToken
    setLoading(true)

    let projectId = ''
    let ghFilePath = ''
    await uploadGh(acceptedFiles[0], token)
      .then(r => {
        setProjectId(r.projectId)
        projectId = r.projectId
        ghFilePath = r.ghFilePath
      })
      .catch(e =>
        setError([
          ...('Something went wrong with uploading. Refresh and try again. Or contact the dev team.' +
            e)
        ])
      )

    const header = { Authorization: `Bearer ${token}` }
    const json = props.projectJson
    json['projectId'] = projectId
    json['modelFilePath'] = ghFilePath
    await submitUploadForm(json, header)
      .then(r => {
        setLoading(false)
        setSuccess(true)
      })
      .catch(e => {
        setError([
          ...('Something went wrong with uploading project data. Refresh and try again. Or contact the dev team.' +
            e)
        ])
        return
      })
  }

  const handle = e => {
    setLoading(true)
    handleDrop()
    // dispatch(grasshopperModulesActions.)
  }

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {(file.size / 1000000).toFixed(2)} MB
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  const unClicked = () => {
    //do nothing
  }

  return (
    <section className="container" style={{ height: '100%' }}>
      {success ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Lottie
            width="100px"
            height="100px"
            options={defaultOptions}
          ></Lottie>
          <Typography variant="h5">Upload successful</Typography>
          <Typography variant="body1">
            Share your space (Arup internal):
          </Typography>
          <CopyLink projectId={'/project/' + projectId}></CopyLink>
          <div style={{ position: 'absolute', bottom: '20px', right: '2rem' }}>
            <arc-button
              type="filled"
              color="secondary"
              submit={true}
              onClick={onClickButton}
            >
              View your space
            </arc-button>
          </div>
        </div>
      ) : (
        <Grid
          container
          spacing={6}
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item sm={6}>
            <h2>Upload Project</h2>

            <div {...getRootProps({ className: 'dropzone', style })}>
              <input {...getInputProps()} />
              <p>Drop your .gh file here to see some magic.</p>
            </div>
            <aside>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '150px',
                  overflow: 'scroll'
                }}
              >
                {error.map((text, index) => (
                  <Typography
                    key={index}
                    variant="caption"
                    color="error"
                    style={{ marginTop: '10px' }}
                  >
                    {text}
                  </Typography>
                ))}
              </div>

              <h4>Files</h4>
              <p>Total Size: {totalSize} MB</p>
              <Container
                fixed
                style={{
                  maxHeight: '200px',
                  overflow: 'auto',
                  marginBottom: 15
                }}
              >
                <ul>
                  {confirmedFiles.map(file => (
                    <li key={file.name}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="caption">
                          {file.name.substring(0, 31)}... -{' '}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          style={{ marginLeft: 5 }}
                        >
                          {(file.size / 1000000).toFixed(2)} MB
                        </Typography>
                        {file['status'] === 'uploaded' ? (
                          <CheckIcon sx={{ ml: 1 }}></CheckIcon>
                        ) : (
                          ''
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </Container>

              <div
                style={{ position: 'absolute', bottom: '20px', right: '2rem' }}
              >
                {confirmedFiles.length === 0 ? (
                  <arc-button type="filled" color="secondary" disabled>
                    Submit
                  </arc-button>
                ) : isLoading ? (
                  <arc-button type="filled" color="secondary" loading={true}>
                    Submit
                  </arc-button>
                ) : (
                  <arc-button type="filled" color="secondary" onClick={handle}>
                    Submit
                  </arc-button>
                )}
              </div>
              {fileRejections ? (
                <>
                  {/* <h4>Rejected files</h4> */}
                  <ul>{fileRejectionItems}</ul>
                </>
              ) : (
                ''
              )}
            </aside>
          </Grid>
          <Grid item sm={6} style={{ height: '50vh', overflow: 'scroll' }}>
            <>
              {/* {previewData &&
                previewData.inputs.map(inputGroup => (
                  <DashboardInputCard
                    key={inputGroup.name}
                    index={inputGroup.id}
                    userRole={inputGroup.userRole}
                    projectId={inputGroup.projectId}
                    initialNames={initializeInputNames(previewData)}
                    initialValues={initializeInputData(previewData)}
                  />
                ))} */}
              {totalSize > 0 && !previewData && error.length === 0 && (
                <LoaderContainer>
                  <CircularProgress />
                </LoaderContainer>
              )}
              {error.length > 0 && (
                <ErrorContainer>
                  <ErrorIcon color="error" width="3em" height="3em" />
                </ErrorContainer>
              )}
            </>
          </Grid>
        </Grid>
      )}
    </section>
  )
}
export default FileUpload

const LoaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
})

const ErrorContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%'
})
