// TODO - consider using different package
// other than react-grid-carousel, as it's missing type declarations
import AddIcon from '@mui/icons-material/Add'
import { Card, Typography } from '@mui/material'
import Carousel from 'react-grid-carousel'
import { useNavigate } from 'react-router-dom'
import { ProjectCard } from '../card/ProjectCard'
import { ProjectHighlightCard } from '../card/ProjectHighlightCard'

interface IProps {
  // projectId: string;
  items: any
  addProject: boolean
  showDots: boolean
  rows?: number
  cardType: 'projectCard' | 'highlightCard'
}

const CardTypes = {
  projectCard: ProjectCard,
  highlightCard: ProjectHighlightCard
}

const carouselLayout = (rows: number) => {
  return [
    {
      breakpoint: 576,
      cols: 1,
      rows: rows,
      gap: 5
    },
    {
      breakpoint: 768,
      cols: 1,
      rows: rows,
      gap: 10
    },
    {
      breakpoint: 992,
      cols: 2,
      rows: rows,
      gap: 10
    },
    {
      breakpoint: 1200,
      cols: 3,
      rows: rows,
      gap: 20
    },
    {
      breakpoint: 1600,
      cols: 3,
      rows: rows,
      gap: 20
    },
    {
      breakpoint: 1900,
      cols: 4,
      rows: rows,
      gap: 20
    },
    {
      breakpoint: 2200,
      cols: 5,
      rows: rows,
      gap: 20
    },
    {
      breakpoint: 4000,
      cols: 6,
      rows: rows,
      gap: 20
    }
  ]
}

export const ProjectGrid = (props: IProps) => {
  const navigate = useNavigate()

  const onClickSelect = () => {
    navigate('/upload')
  }

  return (
    <Carousel
      responsiveLayout={carouselLayout(props.rows ? props.rows : 1)}
      showDots={props.showDots}
      hideArrow={false}
    >
      {props.addProject === true ? (
        <Carousel.Item>
          <Card
            key="add_new_project"
            // onClick={onClickShowModuleSelectModal}
            // onClick={onClickNewProject}
            style={{
              width: '200px',
              minHeight: '200px',
              height: '100%',
              boxShadow: 'none',
              borderStyle: 'solid',
              borderWidth: '2px',
              borderColor: '#EFEFEF',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
            onClick={onClickSelect}
          >
            <Typography id="newProjectCard" variant="h5" component="div">
              <AddIcon></AddIcon>
              <Typography>Add Project</Typography>
            </Typography>
          </Card>
        </Carousel.Item>
      ) : null}
      {props.items.map((val, i) => {
        const CardType = CardTypes[props.cardType]
        return (
          <Carousel.Item key={i}>
            <CardType
              key={val._id}
              projectId={val.projectId}
              title={val.title}
              projectImage={
                val.image
                  ? val.image
                  : `${
                      process.env.PUBLIC_URL
                    }/assets/sampleImages/sampleImage${Math.floor(
                      (Math.random() + 0.5) * 3
                    )}.jpg`
              }
              description={val.description}
              createdBy={val.createdBy}
              creatorId={val.creatorId}
              public={val.public}
              allData={val}
              version={undefined}
              ghPath={val.ghFilePath}
            ></CardType>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}
