import type { Action, Reducer } from 'redux'

import type { KnownAction } from './actions'

export interface ISelectedObjects {
  selectedObjects: any | null
}

export const selectedObjectsReducer: Reducer<ISelectedObjects> = (
  state: ISelectedObjects = { selectedObjects: [] },
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction

  switch (action.type) {
    case 'INSERT_SELECTED_OBJECT':
      return {
        ...state,
        selectedObjects: [
          ...state.selectedObjects,
          { ...action.selectedObject }
        ]
      }
    case 'REMOVE_SELECTED_OBJECT': {
      return {
        selectedObjects: state.selectedObjects.filter(
          o => o.id !== action.selectedObject.id
        )
      }
    }
    case 'RESET_SELECTED_OBJECTS': {
      return {
        ...state,
        selectedObjects: []
      }
    }
    default:
      return state
  }
}
