import { useToken } from '~api/useToken'
import { postRhinoComputeCalculation } from '~api/inform/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { InFormError, RhinoComputeType } from './types'

export const useComputeEngine = () => {
  const { getToken } = useToken()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationKey: ['computeModel'],
    retry: 3,
    mutationFn: async (body: RhinoComputeType) => {
      return postRhinoComputeCalculation(body, await getToken())
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['computeModel', variables.inputs], data)
    },
    onError: (error: InFormError, variables, context) => {
      return error
    }
  })

  return {
    mutation: mutation
  }
}
