import { useMsal } from '@azure/msal-react'
import { Person, ViewType } from '@microsoft/mgt-react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Chip,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Popper,
  Typography
} from '@mui/material'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { useRef, useState } from 'react'
import { downloadGh } from '~api/inform/api'
import ProjectForm from '../form/ProjectForm'
import './ProjectCard.css'

interface IProps {
  public: boolean
  avatar?: string | undefined
  name?: string
  allData?: any
  projectId?: string
  creatorId?: string
  ghPath?: string
  title: string
}

export const CardBar = (props: IProps) => {
  const anchorRef = useRef(null) as any
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const { accounts } = useMsal()

  const [inputs, setInputs] = useState({
    createdBy: accounts[0]?.name
  })
  const signedInUserId = accounts[0]?.localAccountId

  const onClickDelete = () => {
    if (!signedInUserId) {
      return
    }

    if (props.projectId) {
      //
    } else {
      //
    }
  }

  const onClickDownload = () => {
    downloadGh(props.ghPath, localStorage.getItem('accessToken')).then(res =>
      axios
        .get(res.uri, {
          responseType: 'blob'
        })
        .then(res => {
          fileDownload(res.data, `${props.title}.gh`)
        })
    )
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = event => {
    setOpenModal(false)
  }

  const handleOpenEdit = () => {
    setOpenModalEdit(true)
  }
  const handleCloseEdit = () => {
    setOpenModalEdit(false)
  }

  const onChangeInput = event => {
    setInputs(event)
  }

  const onClickSubmit = event => {
    if (props.projectId) {
      handleCloseEdit()
    }
  }
  return (
    <div
      className="CardBar"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '3px'
      }}
    >
      <div
        className="MenuLeft"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Person
          userId={props.creatorId}
          avatarSize="small"
          view={ViewType.twolines}
          line2Property="jobTitle"
        ></Person>
        {props.public ? (
          <Chip
            label="private"
            size="small"
            style={{ height: 20, fontSize: '0.6rem', marginLeft: '5px' }}
          />
        ) : null}
      </div>
      <div
        className="MenuRight"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon
            color="secondary"
            style={{ width: 16, height: 16 }}
          ></MoreVertIcon>
        </IconButton>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    {props.ghPath ? (
                      <MenuItem onClick={onClickDownload}>
                        <Typography variant="body1">Download .gh</Typography>
                      </MenuItem>
                    ) : (
                      ''
                    )}
                    {signedInUserId === props.creatorId ? (
                      <>
                        <MenuItem dense onClick={handleOpenEdit}>
                          <Typography variant="body1">Edit</Typography>
                        </MenuItem>
                        <MenuItem dense onClick={handleOpenModal}>
                          <Typography variant="body1" color="error">
                            Delete
                          </Typography>
                        </MenuItem>
                      </>
                    ) : (
                      ''
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Paper sx={{ width: 400, height: 180, padding: '1rem' }}>
            <Typography variant="h6">Delete {props.title}?</Typography>
            <p id="parent-modal-description">
              Are you sure you want to delete project: {props.title}?
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right'
              }}
            >
              <arc-button
                type="outlined"
                size="small"
                onClick={handleCloseModal}
              >
                No
              </arc-button>
              <arc-button
                type="filled"
                color="error"
                size="small"
                onClick={onClickDelete}
                style={{ marginLeft: '5px' }}
              >
                Delete
              </arc-button>
            </div>
          </Paper>
        </Modal>
        <Modal
          open={openModalEdit}
          onClose={handleCloseEdit}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Paper sx={{ padding: '2rem' }}>
            <Typography variant="caption" color="error">
              Update
            </Typography>
            <Typography variant="h6">Edit {props.title}</Typography>
            <ProjectForm
              values={props.allData}
              onChangeInput={onChangeInput}
            ></ProjectForm>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <arc-button
                type="filled"
                color="secondary"
                submit={true}
                onClick={onClickSubmit}
              >
                Update
              </arc-button>
            </div>
          </Paper>
        </Modal>
      </div>
    </div>
  )
}
