import type { PsOption } from '../projectInfoObjects/types'

export type PsRootObjectActions =
  | ResetRootObject
  | SetDesignOptions
  | SetDesignOptionCount
  | SetParallelCoordinateData

export const SET_DESIGN_OPTIONS = 'SET_DESIGN_OPTIONS'
export const RESET_ROOT_OBJECT = 'RESET_ROOT_OBJECT'
export const SET_PARALLEL_COORDINATE_DATA = 'SET_PARALLEL_COORDINATE_DATA'
export const SET_DESIGN_OPTION_COUNT = 'SET_DESIGN_OPTION_COUNT'

export type ResetRootObject = {
  type: 'RESET_ROOT_OBJECT'
}

export type SetDesignOptions = {
  type: 'SET_DESIGN_OPTIONS'
  options: PsOption[]
}

export type SetDesignOptionCount = {
  type: 'SET_DESIGN_OPTION_COUNT'
  count: number
}

export type SetParallelCoordinateData = {
  type: 'SET_PARALLEL_COORDINATE_DATA'
  data: object
}
