import { useMsal } from '@azure/msal-react'
import { Grid, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { useQueryClient } from '@tanstack/react-query'
import type { RefObject } from 'react'
import { createRef, useEffect, useMemo, useRef, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import Lottie from 'react-lottie'
import { useLocation } from 'react-router-dom'
import { getUserRole } from 'src/lib/auth/auth'
import { computeAbortController } from '~api/inform/api'
import type { RhinoComputeResponse } from '~api/inform/types'
import { useComponents } from '~api/inform/useComponents'
import { useComputeEngine } from '~api/inform/useComputeEngine'
import { useProjectInfo } from '~api/inform/useProjectInfo'
import { useStaticGeometry } from '~api/inform/useStaticGeometry'
import {
  importContextUnity,
  importEmptyUnity,
  importGeometryUnity
} from '~api/unity/apiUnity'
import InformText from '~assets/lottie/InformText.json'
import { AnalysisState } from '~components/types'
import InputSidebar from '~pages/inputs/InputSidebar'
import type { IInputReturnData } from '~pages/inputs/components/types/types'
import OutputSidebar from '~pages/outputs/OutputSidebar'
import UnityMount, {
  unityContext
} from '~pages/dashboard/components/UnityMount'
import AnalysisStateSnackbar from './components/AnalysisStateSnackbar'
import TitleBar from './components/TitleBar'
import UnitySettingsButton from './components/ViewerSettingsBar'
import { InputFormContext } from './types'

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: InformText,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const Dashboard = () => {
  const location = useLocation()
  const unityMountRef: RefObject<HTMLDivElement> = createRef()
  const theme = useTheme()

  const [projectId] = useState(
    location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
  )
  console.log(projectId)
  const [analysisState, setAnalysisState] = useState<AnalysisState>(
    AnalysisState.none
  )
  const [showInputSideBar, setShowInputSideBar] = useState<boolean>(true)
  const [showOutputSideBar] = useState<boolean>(true)
  const [openSettingsMenu, setOpenSettingsMenu] = useState<boolean>(false)
  const [splashScreen, setSplashScreen] = useState<boolean>(true)

  const [unityLoadingState, setUnityLoadingState] = useState<number>(0)
  const [unityReady, setUnityReady] = useState<boolean>(false)

  const { mutation } = useComputeEngine()
  const { projectInfo } = useProjectInfo(projectId)

  const [computeEngineResponse, setComputeEngineResponse] =
    useState<RhinoComputeResponse>()

  const { staticGeometry } = useStaticGeometry(projectId)
  const lottieRef = useRef()
  const { accounts } = useMsal()

  const components = useComponents(projectId)
  const queryClient = useQueryClient()

  const layerForm = useForm<Record<string, boolean>>()
  const inputForm = useForm<Record<string, string[] | number[]>>({
    defaultValues: components.defaultInputValues
  })

  /*
  Unity loading progress.
  */
  useEffect(() => {
    unityContext.on('progress', function (progression) {
      setUnityLoadingState(Math.max(0, progression * 100.0))
    })
    unityContext.on('loaded', function () {
      setUnityReady(true)
    })
    unityContext.on('FinishImport', function () {
      if (analysisState === AnalysisState.importing) {
        setSplashScreen(false)
        setAnalysisState(AnalysisState.success)
      }
    })
  })

  /*
  Update the analysisstate based on the computeModel mutation.
  */
  useEffect(() => {
    if (mutation.isSuccess) {
      setAnalysisState(AnalysisState.importing)
    }
    if (mutation.isLoading) {
      setAnalysisState(AnalysisState.analysis)
    }
    if (mutation.isError) {
      mutation.error.code === 2
        ? setAnalysisState(AnalysisState.abort)
        : setAnalysisState(AnalysisState.error)
    }
  }, [mutation.status])

  /*
  Import static geometry in Unity on loading the component (if the project has static geometry).
  */
  useEffect(() => {
    if (staticGeometry && staticGeometry.outputs && unityReady) {
      importContextUnity(staticGeometry.outputs)
    }
  }, [staticGeometry, unityReady])

  /*
  Add geometry to Unity Viewer when compute engine is done calculating
  */
  useMemo(() => {
    if (computeEngineResponse && unityReady) {
      importGeometryUnity(computeEngineResponse.outputs)
    }
  }, [computeEngineResponse, unityReady])

  /*
    When an error occurs, send an empty geometry to reset the unity canvas.
    */
  useMemo(() => {
    if (analysisState === AnalysisState.error) {
      importEmptyUnity()
    }
  }, [analysisState])

  /*
  Submit calculation to compute server.
  */
  const handleSubmitCalculation: SubmitHandler<
    Record<string, string[] | number[]>
  > = async data => {
    if (projectId && components.inputComponents) {
      const inputs = Object.entries(data)
      const requestInputs = inputs.map(o => {
        return { name: o[0], value: o[1] }
      })

      const cacheQuery = queryClient.getQueryData<
        RhinoComputeResponse | undefined
      >(['computeModel', requestInputs])
      if (cacheQuery) {
        setComputeEngineResponse(cacheQuery)
        setAnalysisState(AnalysisState.importing)
        return
      }
      try {
        const response = await mutation.mutateAsync({
          inputs: requestInputs,
          projectId: projectId,
          ghFilePath:
            components.inputComponents[0].groups[0].items[0].rhinocompute
        })
        setComputeEngineResponse(response)
      } catch {
        //
      }
    }
  }

  /*
Retries a calculation based on previous inputs.
*/
  const handleRetryCalculation = async (
    inputs: IInputReturnData[] | undefined
  ) => {
    if (projectId && components.inputComponents && inputs) {
      try {
        const response = await mutation.mutateAsync({
          inputs: inputs,
          projectId: projectId,
          ghFilePath:
            components.inputComponents[0].groups[0].items[0].rhinocompute
        })
        setComputeEngineResponse(response)
      } catch {
        //
      }
    }
  }

  /*
  Aborts the mutation on the frontend. (The backend will still process the data, but won't return it.)
  */
  const handleCancel = async () => {
    computeAbortController.abort()
  }

  return (
    <>
      <InputFormContext.Provider
        value={{ layersForm: layerForm, inputsForm: inputForm }}
      >
        <Grid container width={'100%'} columns={16}>
          <Grid
            item
            xs={5}
            md={4}
            lg={4}
            xl={3}
            style={{ overflowY: 'scroll', height: 'calc(100vh - 3rem)' }}
          >
            {showInputSideBar && components.inputComponents && projectId && (
              <InputSidebar
                userRole={getUserRole(accounts[0])}
                onSubmit={handleSubmitCalculation}
                computeMutation={mutation}
                outputData={computeEngineResponse}
                components={components}
              />
            )}
          </Grid>
          <Grid item xs={6} md={8} lg={8} xl={10}>
            <div
              ref={unityMountRef}
              style={{
                width: '100%',
                height: 'calc(100vh - 3rem)',
                position: 'relative',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <UnitySettingsButton
                showOutputSideBar={showOutputSideBar}
                showUnitySettings={openSettingsMenu}
                setShowUnitySettings={setOpenSettingsMenu}
              />
              {splashScreen && (
                <LoadingText theme={theme}>
                  <Lottie
                    width={'400px'}
                    height={'90px'}
                    ref={lottieRef}
                    options={defaultOptions}
                  />
                  {!unityReady && (
                    <Typography color="text.secondary">
                      Preparing the viewer...
                    </Typography>
                  )}
                  {analysisState === AnalysisState.analysis && (
                    <Typography color="text.secondary">
                      Analyzing default option...
                    </Typography>
                  )}
                </LoadingText>
              )}
              {projectInfo && (
                <TitleBar
                  showInputSideBar={showInputSideBar}
                  setShowInputSideBar={setShowInputSideBar}
                  title={projectInfo.title}
                  creatorId={projectInfo.creatorId}
                  userRole={getUserRole(accounts[0])}
                />
              )}
              <AnalysisStateSnackbar
                onCancel={handleCancel}
                analyisState={analysisState}
                expectedAnalysisTime={projectInfo?.expectedAnalysisTime}
              ></AnalysisStateSnackbar>
              <UnityMount />
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            lg={4}
            xl={3}
            style={{
              overflowY: 'scroll',
              height: 'calc(100vh - 3rem)',
              padding: '1rem 0rem 1rem 1rem'
            }}
          >
            {showOutputSideBar && (
              <OutputSidebar
                analysisState={analysisState}
                computeMutation={mutation}
                outputData={computeEngineResponse}
                components={components}
                retryAnalysis={handleRetryCalculation}
              />
            )}
          </Grid>
        </Grid>
      </InputFormContext.Provider>
    </>
  )
}
export default Dashboard

const LoadingText = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper
}))
