import { useToken } from '~api/useToken'
import { getProjectByUser } from './api'
import { useQuery } from '@tanstack/react-query'

/**
 * Returns a paginated list of projects based on the userId in embedded in the Auth token.
 *
 * @returns Paginated list of projects
 */
export const useProjectsByUser = () => {
  const { getToken } = useToken()
  const { data, isLoading, error } = useQuery({
    queryKey: ['projects'],
    queryFn: async () => getProjectByUser(await getToken()),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })

  return {
    projects: data ? data : undefined,
    isLoading,
    error
  }
}
