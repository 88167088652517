import type { Action, Reducer } from 'redux'
import type { KnownAction } from './actions'

export interface IImageObjects {
  imageObject: any
  imageName: any
  imagesSuccess: boolean
}

export const imageObjectsReducer: Reducer<IImageObjects> = (
  state: IImageObjects = {
    imageObject: {},
    imageName: null,
    imagesSuccess: true
  },
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction

  switch (action.type) {
    case 'RESET_IMAGE_OBJECTS':
      return {
        ...state,
        imageObject: {},
        imageName: null,
        imagesSuccess: true
      }
    case 'INSERT_IMAGE_OBJECT':
      return {
        ...state,
        imageObject: { ...state.imageObject, ...action.imageObject }
      }

    case 'REMOVE_IMAGE_OBJECT': {
      return { ...state, imageObject: { ...action.imageObject } }
    }
    case 'INSERT_IMAGE_NAME': {
      return { ...state, imageName: action.imageName }
    }
    case 'INITIAL_INSERT_SUCCEEDED': {
      return { ...state, imagesSuccess: action.imagesSuccess }
    }
    default:
      return state
  }
}
