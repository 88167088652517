import '@arc-web/components/dist/components/button/arc-button'
import { useMsal } from '@azure/msal-react'
import CheckIcon from '@mui/icons-material/Check'
import { Container, Grid, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Lottie from 'react-lottie'
import { uploadRhinoStatic } from '~api/inform/api'
import successLottie from '~assets/lottie/success.json'
import { getAccessToken } from '../../lib/auth/auth'

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

type IProps = {
  projectId: string
}

const RhinoUpload = (props: IProps) => {
  const [success, setSuccess] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [totalSize, setTotalSize] = useState<number>(0.0)
  const [confirmedFiles, setConfirmedFiles] = useState<any>([])
  const [error, setError] = useState<string>('')
  const [previewData] = useState<any>()
  const { accounts, instance } = useMsal()
  const [accessToken, setAccessToken] = useState<string>()

  useEffect(() => {
    getAccessToken(accounts[0], instance).then(token => setAccessToken(token))
  }, [accounts, instance])

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragReject
  } = useDropzone({
    maxFiles: 1,
    maxSize: 50e6,
    accept: '.3dm',
    onDrop: acceptedFiles => {
      setError('')
      setConfirmedFiles([])
      acceptedFiles.map(file => {
        return setTotalSize(
          totalSize => totalSize + parseFloat((file.size / 1e6).toFixed(2))
        )
      })
      setConfirmedFiles([...acceptedFiles])
    }
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragReject]
  )

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const handleDrop = async () => {
    const token = accessToken
    setLoading(true)
    if (acceptedFiles.length === 0) {
      //
    }
    const projectId = props.projectId

    await uploadRhinoStatic(acceptedFiles[0], token, projectId)
      .then(r => {
        setLoading(false)
        setSuccess(true)
      })
      .catch(e =>
        setError(
          'Something went wrong with uploading. Refresh and try again. Or contact the dev team.' +
            e
        )
      )
  }

  const handle = e => {
    setLoading(true)
    handleDrop()
  }

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {(file.size / 1000000).toFixed(2)} MB
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  return (
    <section className="container" style={{ height: '100%' }}>
      {success ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Lottie
            width="100px"
            height="100px"
            options={defaultOptions}
          ></Lottie>
          <Typography variant="h5">Upload successful</Typography>
        </div>
      ) : (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: '10px', flexDirection: 'column' }}
        >
          <p>
            {
              'Upload a rhinofile with static geometry. This geometry will be loaded once.'
            }
          </p>

          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>Drop your .3dm file.</p>
          </div>
          <aside>
            <Typography variant="subtitle1" color="error">
              {error}
            </Typography>
            <h4>Files</h4>
            <p>Total Size: {totalSize} MB</p>
            <Container
              fixed
              style={{
                maxHeight: '200px',
                overflow: 'auto',
                marginBottom: 15
              }}
            >
              <ul>
                {confirmedFiles.map(file => (
                  <li key={file.name}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="caption">
                        {file.name.substring(0, 31)}... -{' '}
                      </Typography>
                      <Typography variant="subtitle2" style={{ marginLeft: 5 }}>
                        {(file.size / 1000000).toFixed(2)} MB
                      </Typography>
                      {file['status'] === 'uploaded' ? (
                        <CheckIcon sx={{ ml: 1 }}></CheckIcon>
                      ) : (
                        ''
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </Container>

            <div
              style={{ position: 'absolute', bottom: '20px', right: '2rem' }}
            >
              {confirmedFiles.length === 0 ? (
                <arc-button type="filled" color="secondary" disabled>
                  Submit
                </arc-button>
              ) : isLoading ? (
                <arc-button type="filled" color="secondary" loading={true}>
                  Submit
                </arc-button>
              ) : (
                <arc-button type="filled" color="secondary" onClick={handle}>
                  Submit
                </arc-button>
              )}
            </div>
            {fileRejections ? (
              <>
                {/* <h4>Rejected files</h4> */}
                <ul>{fileRejectionItems}</ul>
              </>
            ) : (
              ''
            )}
          </aside>
        </Grid>
      )}
    </section>
  )
}
export default RhinoUpload
