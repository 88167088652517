import LightbulbIcon from '@mui/icons-material/Lightbulb'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import { ToggleButton, Typography } from '@mui/material'
import { memo, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useStaticGeometry } from '~api/inform/useStaticGeometry'
import {
  setLayerStateContextUnity,
  setLayerStateUnity
} from '~api/unity/apiUnity'
import { InformCard } from '~components/card/BaseCard'
import { useInputFormContext } from '~pages/dashboard/useInputFormContext'

interface IProps {
  meshLayers: string[]
}

const LayerStates = ({ meshLayers }: IProps) => {
  const { layersForm } = useInputFormContext()
  const location = useLocation()
  const { control } = layersForm

  const { staticGeometry } = useStaticGeometry(
    location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
  )

  const handleToggleLayer = (param: string, state: boolean) => {
    setLayerStateUnity({ param: param, state: state })
  }

  const handleToggleContextLayer = (param: string, state: boolean) => {
    setLayerStateContextUnity({ param: param, state: state })
  }

  return (
    <InformCard name={'Mesh Layers'} subtitle={'Toggle the layer visibility.'}>
      <form>
        <Typography variant="subtitle2">Dynamic geometry</Typography>
        {meshLayers.map(layer => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '7px'
            }}
          >
            <Typography variant="body1" color="info">
              {layer.split('rh_out:')[1].replace('-analysis', '')}
            </Typography>
            <Controller
              key={layer}
              name={layer}
              control={control}
              defaultValue={true}
              render={({ field: { onChange, value } }) => (
                <ToggleButton
                  value="check"
                  size="small"
                  selected={value}
                  onChange={() => {
                    onChange(!value)
                    handleToggleLayer(layer, !value)
                  }}
                  sx={{ height: '30px', width: '30px' }}
                >
                  {value ? (
                    <LightbulbIcon
                      color="info"
                      sx={{ height: '15px', width: '15px' }}
                    />
                  ) : (
                    <LightbulbOutlinedIcon
                      sx={{ height: '15px', width: '15px' }}
                    />
                  )}
                </ToggleButton>
              )}
            ></Controller>
          </div>
        ))}
        {staticGeometry && staticGeometry.outputs && (
          <Typography variant="subtitle2" style={{ marginTop: '10px' }}>
            Context
          </Typography>
        )}
        {staticGeometry &&
          Object.keys(staticGeometry.outputs).map(layer => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px'
              }}
            >
              <Typography variant="body1" color="info">
                {layer}
              </Typography>
              <Controller
                key={layer}
                name={layer}
                control={control}
                defaultValue={true}
                render={({ field: { onChange, value } }) => (
                  <ToggleButton
                    value="check"
                    size="small"
                    selected={value}
                    onChange={() => {
                      onChange(!value)
                      handleToggleContextLayer(layer, !value)
                    }}
                    sx={{ height: '30px', width: '30px' }}
                  >
                    {value ? (
                      <LightbulbIcon
                        color="info"
                        sx={{ height: '15px', width: '15px' }}
                      />
                    ) : (
                      <LightbulbOutlinedIcon
                        sx={{ height: '15px', width: '15px' }}
                      />
                    )}
                  </ToggleButton>
                )}
              ></Controller>
            </div>
          ))}
      </form>
    </InformCard>
  )
}

export default memo(LayerStates)
