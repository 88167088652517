import {
  Slider,
  TextField,
  Typography,
  styled,
  useTheme,
  Tooltip
} from '@mui/material'
import React, { useState } from 'react'
import { InputItem } from './styles/styles'
import type { IInputItem } from './types/types'

const SliderItem: React.FC<IInputItem> = ({
  component,
  disabled,
  onChange,
  onBlur,
  name,
  value,
  setError,
  invalid,
  clearError
}: IInputItem) => {
  const theme = useTheme()
  const [hasErrors, setHasErrors] = useState(false)
  const handleFieldChange = (val: number) => {
    //Validate
    if (
      val >= component.validation[0]._v.min &&
      val <= component.validation[0]._v.max
    ) {
      if (hasErrors) {
        setHasErrors(false)
        clearError(name)
      }
    } else {
      if (!hasErrors) {
        setHasErrors(true)
        setError(name, {
          type: 'custom',
          message: `The value must be between ${component.validation[0]._v.min} and ${component.validation[0]._v.max}`
        })
      }
    }
    onChange([val])
  }

  const marks = [
    {
      value: component.validation[0]._v.min,
      label: component.validation[0]._v.min
    },
    {
      value: component.validation[0]._v.max,
      label: component.validation[0]._v.max
    }
  ]

  return (
    <InputItem>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
        >
          <Tooltip title={component.name}>
            <Typography
              variant="body1"
              color="text.secondary"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              {component.name}
            </Typography>
          </Tooltip>
        </div>
        <div style={{ display: 'flex', marginLeft: '2px' }}>
          <TextField
            size="small"
            // type="number"
            variant="standard"
            style={{ width: '60px' }}
            hiddenLabel
            disabled={disabled}
            value={value}
            onChange={e => handleFieldChange(Number(e.target.value))}
            inputProps={{ style: { fontWeight: 500, marginLeft: '4px' } }}
            error={invalid}
          />
        </div>
      </div>
      <InformSlider
        theme={theme}
        onChange={(e, v) =>
          handleFieldChange((v as number[]).length > 0 ? v[0] : v)
        }
        value={value as number}
        defaultValue={component.default[0]._v.value}
        min={component.validation[0]._v.min}
        max={component.validation[0]._v.max}
        step={
          component.validation[0]._v.interval === 0
            ? 10 ** -component.validation[0]._v.digits
            : component.validation[0]._v.interval
        }
        disabled={disabled}
        // marks={marks}
        onBlur={onBlur}
        name={name}
      ></InformSlider>
    </InputItem>
  )
}

export default SliderItem

const InformSlider = styled(Slider)(({ theme }) => ({
  color: 'rgb(var(--arc-red-050))',
  height: 5,
  marginBottom: 0,
  padding: 0,
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-thumb': {
    height: 17,
    width: 17,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-mark': {
    display: 'none'
  },
  '& .MuiSlider-markLabel': {
    top: '25px',
    fontSize: '0.7rem',
    '&[data-index="0"]': {
      margin: 'auto!important',
      display: 'table'
    },
    '&:before': {
      display: 'none'
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000'
    }
  }
}))
