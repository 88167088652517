import type {
  AccountInfo,
  Configuration,
  IPublicClientApplication
} from '@azure/msal-browser'
import { PublicClientApplication } from '@azure/msal-browser'

import config from '~config'
import type { IProjectRoleMapping } from '../../declarations'

export enum UserRoles {
  admin = 'Admin',
  user = 'User',
  guest = 'Guest'
}

export const getUserRole = (account: AccountInfo) => {
  if (!account) {
    return
  }
  const roles = account.idTokenClaims && account.idTokenClaims.roles

  return roles?.includes('Admin')
    ? UserRoles.admin
    : roles?.includes('Project.CRUD')
    ? UserRoles.user
    : UserRoles.guest
}

const initMsal = () => {
  const msalConfig: Configuration = {
    auth: {
      clientId: config.activeDirectory.appId,
      authority: 'https://login.microsoftonline.com/arup.onmicrosoft.com',
      redirectUri: config.activeDirectory.redirectUri
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  }

  return new PublicClientApplication(msalConfig)
}

export const msal = initMsal()

export const loginRequest = {
  scopes: ['email', 'openid', 'profile', 'User.Read', 'User.ReadBasic.All']
}

export const getAccessToken = async (
  account: AccountInfo,
  instance: IPublicClientApplication,
  scopes = ['api://3605cbd3-bf41-4119-bf3c-81d6bbe8923b/user_impersonation']
) => {
  const response = await instance.acquireTokenSilent({
    scopes,
    account
  })
  return response.idToken
}

export const getAvatar = async (
  account: AccountInfo,
  instance: IPublicClientApplication
) => {
  const token = await getAccessToken(account, instance, loginRequest.scopes)
  const avatarRes = await fetch(
    'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'image/jpg'
      }
    }
  )

  return URL.createObjectURL(await avatarRes.blob())
}

export const getAvatarById = async (account: AccountInfo, userId: string) => {
  const token = await getAccessToken(account, msal, loginRequest.scopes)
  const avatarRes = await fetch(
    `https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'image/jpg'
      }
    }
  )

  return URL.createObjectURL(await avatarRes.blob())
}

export const getUserById = async (account: AccountInfo, userId: string) => {
  const response = await msal.acquireTokenSilent({
    ...loginRequest,
    account
  })

  const user = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${response.accessToken}`,
      'Content-Type': 'application/json'
    }
  })
  return await (user.json() as any)
}

export const getProjectRoleMapping = accountInfo => {
  const projectRoleMapping: IProjectRoleMapping | object = {}
  ;(accountInfo?.accessTokenClaims as any)?.roles.forEach(role => {
    const rle = role.split(':')[0]
    const project = role.split(':')[1]

    if (projectRoleMapping[project]?.length) {
      projectRoleMapping[project].push(rle)
    } else {
      projectRoleMapping[project] = [rle]
    }
  })

  return projectRoleMapping
}
