import type { PsRootObjectActions } from './actions'
import {
  RESET_ROOT_OBJECT,
  SET_DESIGN_OPTIONS,
  SET_DESIGN_OPTION_COUNT,
  SET_PARALLEL_COORDINATE_DATA
} from './actions'
import type { PsRootObjectsBase } from './types'

const initialState: PsRootObjectsBase = {
  designOptions: null,
  parallelCoordinateData: null,
  count: null
}

export const rootObjectsReducer = (
  state = initialState,
  action: PsRootObjectActions
): PsRootObjectsBase => {
  switch (action.type) {
    case RESET_ROOT_OBJECT:
      return {
        designOptions: null,
        parallelCoordinateData: null,
        count: null
      }
    case SET_DESIGN_OPTIONS:
      return { ...state, designOptions: action.options }
    case SET_PARALLEL_COORDINATE_DATA:
      return { ...state, parallelCoordinateData: action.data }
    case SET_DESIGN_OPTION_COUNT:
      return { ...state, count: action.count }
    default:
      return state
  }
}
