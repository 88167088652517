import { ArcButton } from '@arc-web/components/react'
import {
  Alert,
  LinearProgress,
  Link,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import type { UseMutationResult } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import type {
  InFormError,
  RhinoComputeResponse,
  RhinoComputeType
} from '~api/inform/types'
import { ListType } from '~api/inform/types'
import { InformCard } from '~components/card/BaseCard'
import { AnalysisState } from '~components/types'
import { useInputFormContext } from '~pages/dashboard/useInputFormContext'
import type { IInputReturnData } from '~pages/inputs/components/types/types'
import { unityContext } from '~pages/dashboard/components/UnityMount'
import OutputItem from './OutputItem'

type IProps = {
  outputData?: RhinoComputeResponse
  analysisState?: AnalysisState
  computeMutation: UseMutationResult<
    RhinoComputeResponse,
    InFormError,
    RhinoComputeType,
    unknown
  >
  components: any
  retryAnalysis: (inputs: IInputReturnData[] | undefined) => Promise<void>
}

const OutputSidebar = ({
  outputData,
  computeMutation,
  components,
  analysisState,
  retryAnalysis
}: IProps) => {
  const [selected, setSelected] = useState<string | null>(null)
  const theme = useTheme()
  const { inputsForm } = useInputFormContext()
  /*
    If a user clicks on a 3d object, the 'paramName' of the attached data is send from Unity to React.
    Only set selected if the output is of listType 'tree', as it is assumed that treeData is only available for selectedObjects.
    */
  useEffect(() => {
    unityContext.on('SelectedObject', paramName => {
      const selectedOutput = outputData?.outputs[paramName.split('{')[0]]
      selectedOutput &&
      selectedOutput.listType === ListType.Tree &&
      paramName !== ''
        ? setSelected(paramName)
        : setSelected(null)
    })
  })

  /*
    Show that the user aborted the analysis.
    */
  if (analysisState === AnalysisState.abort) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '1.5rem',
            gap: '15px'
          }}
        >
          <Typography variant="h3" color="warning.main" textAlign="center">
            Analysis Canceled
          </Typography>
          <Typography variant="body1">
            The calculation was canceled by the user.
          </Typography>
          <Stack sx={{ width: '100%' }} spacing={2}>
            {computeMutation.error &&
              computeMutation.error.warnings.map(warning => (
                <Alert variant="outlined" severity="warning">
                  {warning}
                </Alert>
              ))}
          </Stack>
          <Typography variant="body1">
            Either retry the calculation you canceled or go back to the last
            successful state.
          </Typography>
          <ArcButton
            size="small"
            style={{ width: '100%' }}
            onClick={() =>
              retryAnalysis(
                computeMutation.variables
                  ? computeMutation.variables.inputs
                  : undefined
              )
            }
          >
            Retry analysis
          </ArcButton>
        </div>
      </>
    )
  }

  /*
    Show errors and warnings if the mutation state turns to error.
    */
  if (analysisState === AnalysisState.error) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography variant="h3" color="error">
            Error
          </Typography>
          <Typography variant="body1">
            The calculation failed to compute.
          </Typography>
        </div>
        <Stack
          sx={{ width: '100%', marginTop: 2, marginBottom: 2 }}
          spacing={2}
        >
          {computeMutation.error &&
            computeMutation.error.errors.map(error => (
              <Alert variant="outlined" severity="error">
                <strong>{error.split(': component')[0]}</strong>
                {error.split(': component')[1]}
              </Alert>
            ))}
          {computeMutation.error &&
            computeMutation.error.warnings.map(warning => (
              <Alert variant="outlined" severity="warning">
                {warning}
              </Alert>
            ))}
        </Stack>
        <Typography variant="body1">
          Refer to the documentation for common issues and fixes:{' '}
          <Link
            href="https://lively-water-096984503.1.azurestaticapps.net/getting-started/#most-common-issues"
            variant="body1"
          >
            most common issues.
          </Link>
        </Typography>
        <ArcButton
          size="small"
          style={{ marginTop: '10px', width: '100%' }}
          onClick={() =>
            retryAnalysis(
              computeMutation.variables
                ? computeMutation.variables.inputs
                : undefined
            )
          }
        >
          Retry analysis
        </ArcButton>
      </>
    )
  }

  return (
    <SideBarContainerRight>
      <div style={{ marginBottom: '5px' }}>
        {analysisState === AnalysisState.analysis && (
          <div
            style={{ width: '100%', marginBottom: '15px', marginTop: '5px' }}
          >
            <LinearProgress
              sx={{
                backgroundColor: theme.palette.background.default,
                height: '5px',
                borderRadius: '3px'
              }}
            />
          </div>
        )}
        {(analysisState === AnalysisState.importing ||
          analysisState === AnalysisState.success) &&
          outputData && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Typography variant="caption" style={{ color: 'grey' }}>
                Last updated:
                {new Date(outputData.updated.$date).toLocaleString('nl', {
                  hour: 'numeric',
                  hour12: true,
                  minute: 'numeric'
                })}
              </Typography>
              <Typography variant="caption" style={{ color: 'grey' }}>
                Calculation time:
                {Math.round(outputData?.calculationTime / 1000)}s
              </Typography>
            </div>
          )}
      </div>
      <div
        style={{
          opacity:
            analysisState === AnalysisState.analysis ||
            inputsForm.formState.isDirty
              ? 0.5
              : 1,
          transition: 'opacity 0.5s'
        }}
      >
        {components.outputComponents &&
          components.outputComponents.length > 0 &&
          components.outputComponents
            //This loops over the 'Cards' that need to be created and sets the card name.
            .filter(o => o.uilocation === 'Analysis')
            .map((value, i) => {
              return (
                <InformCard
                  name={value.name}
                  subtitle={'Output'}
                  updating={analysisState === AnalysisState.analysis}
                >
                  {value.components.map((item, i) => (
                    <OutputItem
                      key={'outputItem' + i}
                      item={item}
                      outputData={outputData?.outputs}
                      isLoading={analysisState === AnalysisState.analysis}
                    ></OutputItem>
                  ))}
                </InformCard>
              )
            })}
        {components.outputComponents &&
          selected &&
          components.outputComponents.length > 0 &&
          components.outputComponents
            //This loops over the 'Cards' that need to be created and sets the card name.
            .filter(o => o.uilocation === 'Property')
            .filter(
              o =>
                Object.keys(o.components[0].mapoutputs._v)[0].toLowerCase() ===
                selected.split('{')[0]
            )
            .map((value, i) => {
              return (
                <InformCard
                  name={value.name}
                  subtitle={'Output'}
                  updating={analysisState === AnalysisState.analysis}
                  chip={'selected'}
                  color="rgb(var(--arc-blue-060))"
                  border="2px solid rgb(var(--arc-blue-060))"
                >
                  {value.components.map((item, i) => (
                    <OutputItem
                      key={'outputItem' + i}
                      item={item}
                      selected={selected}
                      outputData={outputData?.outputs}
                      isLoading={analysisState === AnalysisState.analysis}
                    ></OutputItem>
                  ))}
                </InformCard>
              )
            })}
      </div>
    </SideBarContainerRight>
  )
}

export default OutputSidebar

const SideBarContainerRight = styled('div')({
  overflow: 'hidden'
})
