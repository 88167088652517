import type { Action, Reducer } from 'redux'

import type { KnownAction } from './actions'

export interface IfavoriteObjects {
  favoriteObject: any | null
}

export const favoriteObjectsReducer: Reducer<IfavoriteObjects> = (
  state: IfavoriteObjects = { favoriteObject: [] },
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction

  switch (action.type) {
    case 'INSERT_FAVORITE_OBJECT':
      return {
        ...state,
        favoriteObject: [...state.favoriteObject, { ...action.favoriteObject }]
      }
    case 'REMOVE_FAVORITE_OBJECT': {
      return { favoriteObject: action.favoriteObject }
    }
    case 'RESET_FAVORITE_OBJECTS': {
      return {
        ...state,
        favoriteObject: []
      }
    }
    default:
      return state
  }
}
