import { Skeleton } from '@mui/material'
import Color from 'color'
import { memo, useEffect, useState } from 'react'
import type { ListOutput, TreeOutput } from '~api/inform/types'
import { ListType } from '~api/inform/types'
import { ArupColors } from '~styles/ArcTheme'
import type { IOutputItem } from './types/OutputItem'
import { OutputItemsDictionary } from './types/OutputItem'

const OutputItem: React.FC<IOutputItem> = ({
  item,
  outputData,
  isLoading,
  selected
}: IOutputItem) => {
  const [data, setData] = useState<object | null>(null)
  const Item = OutputItemsDictionary[item.componentname]

  /**
   * Matches the data with the correct UI component by the parameter name.
   */
  useEffect(() => {
    if (outputData) {
      const newData = {}
      const itemPropNames = Object.values(item.mapoutputs._v)
      const paramNames = Object.keys(item.mapoutputs._v)
      for (let i = 0; i < paramNames.length; i++) {
        const output = outputData[paramNames[i].toLowerCase()]

        // Only add data of the selected object by the path.
        if (output.listType === ListType.List) {
          const listOutput = output as ListOutput
          if (!itemPropNames[i]) {
            continue
          }
          newData[itemPropNames[i].toLowerCase()] = listOutput.outputData.value
        }

        // Only add data of the selected object by the path.
        if (output.listType === ListType.Tree && selected) {
          const treeOutput = output as TreeOutput
          const selectedOutput = treeOutput.outputData.find(o =>
            selected.includes(o.path)
          )
          newData[itemPropNames[i].toLowerCase()] = selectedOutput?.value
        }
      }

      //Add colors
      if (Object.keys(newData).includes('colors')) {
        newData['colors'] = newData['colors']
          ? newData['colors'].map(o => Color.rgb(`rgb(${o})`))
          : ArupColors
      }
      setData(newData)
    }
  }, [outputData, isLoading, selected])

  return (
    <>
      {!outputData && <Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
      {data && <Item key={item.componentname} data={data}></Item>}
    </>
  )
}

export default memo(OutputItem)
